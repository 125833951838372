
import { Component, Ref, Vue } from "vue-property-decorator";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Skill } from "@/types";

@Component({
  components: {
    ModalDelete,
  },
})
export default class ModalSkill extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  show = false;
  btnSave = 0;
  isNew = false;
  skill: Skill = new Skill();
  trades = [];
  selectedTrades = [];
  skillTemplateType = [consts.TemplateType.SKILL, consts.TemplateTypeStr.SKILL];

  async showModal(tradeSkill = null) {
    if (tradeSkill === null) {
      this.isNew = true;
      this.skill = new Skill();
    } else {
      this.isNew = false;
      this.skill = tradeSkill.skill;
      if ("trades" in tradeSkill) {
        this.selectedTrades = tradeSkill.trades;
      } else {
        this.selectedTrades.push(tradeSkill.trade);
      }
      this.selectedTrades.forEach((st) => {
        this.onSelect(st);
      });
    }
    this.show = true;
  }

  onSelect(e) {
    const index = this.trades.findIndex((t) => t.id === e.id);
    this.trades[index].checked = true;
  }

  onRemove(e) {
    const index = this.trades.findIndex((t) => t.id === e.id);
    this.trades[index].checked = false;
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteSkill() {
    const response = await API.deleteSkill(this.skill.id);
    if (response.data.success) {
      helpers.info("Skill has been deleted");
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.hideModal();
  }

  async saveModal() {
    if (
      this.skill.code !== "" &&
      this.skill.name !== "" &&
      this.selectedTrades.length > 0
    ) {
      this.skill.trades = this.selectedTrades;
      const response = await API.saveSkill(this.skill);
      if (
        response.data.success === false &&
        response.data.error_code === consts.ErrorCodes.CODE_EXISTS
      ) {
        console.log("Trade code already exists");
        helpers.error("Trade code already exists, please enter another");
        this.btnSave++;
        return;
      } else if (response.data.success === false) {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
      this.$emit(consts.ModalEvents.OK_EVENT);
      this.show = false;
    } else {
      helpers.error("All fields are required.");
      this.btnSave++;
    }
  }

  hideModal() {
    this.selectedTrades = [];
    this.trades = this.trades.map((t) => ({
      ...t,
      checked: false,
    }));
    if (this.isNew === false) {
      this.$emit(consts.ModalEvents.CANCEL_EVENT);
    }
    this.show = false;
  }

  async created() {
    const response = await API.getTrades();
    if (response.data.success) {
      this.trades = response.data.trades;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }
}
