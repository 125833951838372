
import { Component, Prop, Vue } from "vue-property-decorator";
import * as consts from "@/consts";

const SKILLS_T0_SHOW_COUNT = 2;

@Component({
  components: {},
})
export default class WorkTypeCard extends Vue {
  @Prop() workType;
  @Prop() trade;
  skills = [];
  skillsToShowCount = SKILLS_T0_SHOW_COUNT;

  get moreSkills() {
    return this.skills.length - SKILLS_T0_SHOW_COUNT;
  }

  goToWorkType() {
    this.$router.push({
      path: consts.RouterNames.WORK_TYPE,
      name: consts.RouterNames.WORK_TYPE,
      params: {
        trade_id: this.trade.id.toString(),
        work_type_id: this.workType.id.toString(),
      },
    });
  }

  async created() {
    this.skills = this.workType.skills;
  }
}
