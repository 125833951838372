
import { Component, Prop, Vue } from "vue-property-decorator";
import Avatar from "@/components/spec/Avatar.vue";
import * as consts from "@/consts";

@Component({
  components: {
    Avatar,
  },
})
export default class AvatarCard extends Vue {
  @Prop({ default: "" }) bottomTitle!: string;
  // showEmptyProfileImg - czy pokazywac defaultowe zdjecie,
  // jezeli nie to pusty okrag z tekstem Upload image
  @Prop({ default: false }) showEmptyProfileImg!: boolean;
  @Prop({ default: false }) withUpperRightButton!: boolean;
  @Prop({ default: false }) picture!: boolean;
  @Prop({ type: Function, default: null }) loadMethod: any;
  @Prop({ type: Function, default: null }) uploadMethod: any;
  @Prop({ type: Function, default: null }) removeMethod: any;

  bus = new Vue();

  removeAvatar() {
    console.log("RemoveAvatar from AvatarCard");
    this.bus.$emit(consts.Events.REMOVE_EVENT, {});
  }
}
