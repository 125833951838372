
import { Component, Prop, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ChooseUserCard from "@/components/spec/ChooseUserCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as consts from "@/consts";

@Component({
  components: {
    ChooseUserCard,
    TopPanel,
  },
})
export default class ChooseUser extends Vue {
  @Prop({ required: false, default: "accounts", type: String })
  originalView: string;

  header = "";
  breadcrumbs = "";
  userChoices: string[] = [
    consts.UserTypeStr.CUSTOMER_ADMIN,
    consts.UserTypeStr.FACILITY_MANAGER,
    consts.UserTypeStr.CUSTOMER_USER,
    consts.UserTypeStr.TGA_MANAGER,
  ];

  routingHandle(path: string) {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
    });
  }

  created() {
    if (this.originalView === consts.RouterNames.COMPANY) {
      this.header = consts.BreadcrumbTitles.ADD_EMPLOYEE;
      breadcrumbModule.setBreadcrumb({
        routePrefix: "/" + consts.NavNames.DASHBOARD,
        items: [
          {
            title:
              consts.NavNames.CUSTOMER_COMPANIES.charAt(0).toUpperCase() +
              consts.NavNames.CUSTOMER_COMPANIES.slice(1),
            name: consts.NavNames.CUSTOMER_COMPANIES,
          },
          {
            title: this.header,
            name: "",
          },
        ],
      });
      this.userChoices = this.userChoices.slice(0, 3);
    } else {
      this.header = consts.BreadcrumbTitles.CREATE_ACCOUNT;
      breadcrumbModule.setBreadcrumb({
        routePrefix: "/" + consts.NavNames.DASHBOARD,
        items: [
          {
            title:
              consts.NavNames.ACCOUNTS.charAt(0).toUpperCase() +
              consts.NavNames.ACCOUNTS.slice(1),
            name: consts.NavNames.ACCOUNTS,
          },
          {
            title: this.header,
            name: "",
          },
        ],
      });
    }
  }

  chosenUser($emit) {
    const userTypeStr = $emit;
    this.$emit("chosen", userTypeStr);
  }
}
