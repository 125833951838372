import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=41bb73d4&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=ts&"
export * from "./Dashboard.vue?vue&type=script&lang=ts&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=41bb73d4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41bb73d4",
  null
  
)

export default component.exports