
import { Component, Vue, Prop } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { User } from "@/types";
import AuthModule from "@/store/modules/auth";
import Avatar from "@/components/spec/Avatar.vue";
import * as userHelper from "@/helpers/user";

@Component({
  components: {
    Avatar,
  },
})
export default class NavView extends Vue {
  @Prop(Number) height: number;

  navName = "";
  navNames = consts.NavNames;
  routerNames = consts.RouterNames;
  user: User = new User();

  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.user.id);
  }

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  async menuOnClick(navName) {
    console.log({ navName });
    const navElements = this.isLoggedIn.Customer
      ? document.getElementsByName("selection-icon-customer")
      : document.getElementsByClassName("selection-icon");
    this.navName = navName;

    // Clear clicked icons
    for (const element of document.getElementsByClassName("clicked")) {
      element.classList.remove("clicked");
    }
    if (
      [
        consts.NavNames.TGA_MANAGERS,
        consts.NavNames.CUSTOMER_ADMINS,
        consts.NavNames.CUSTOMER_USERS,
        consts.NavNames.FACILITY_MANAGERS,
      ].includes(navName)
    ) {
      this.$router.push({
        path: "accounts",
        name: "accounts",
        query: {
          userType: navName,
        },
      });
    } else if (consts.NavNames.TGA_ADMIN === navName) {
      this.$router.push({
        path: consts.RouterNames.TGA_MANAGER,
        name: consts.RouterNames.TGA_MANAGER,
        params: {
          user_id: String(consts.TGACompany.TGA_ADMIN_ID),
        },
      });
    } else if (
      [
        consts.NavNames.ACCOUNTS,
        consts.NavNames.TGA_COMPANY,
        consts.NavNames.CUSTOMER_COMPANIES,
      ].includes(navName)
    ) {
      this.$router.push({
        path: `/mainView/${navName}`,
      });
    } else if (consts.RouterNames.COMPANY === navName) {
      let companyId = null;
      const response = await API.getCustomerManagerUser(this.user.id);
      if (response.data.success) {
        companyId = response.data.company.id;
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        return;
      }
      this.$router.push({
        path: consts.RouterNames.COMPANY,
        name: consts.RouterNames.COMPANY,
        params: {
          company_id: companyId,
        },
      });
    } else {
      if (this.isLoggedIn.Customer === true) {
        const key = helpers.getKeyByValue(consts.NavNamesCustomer, navName);
        const index = Object.keys(consts.NavNamesCustomer).indexOf(key);
        navElements[index].classList.add("clicked");
      } else {
        const key = helpers.getKeyByValue(consts.NavNames, navName);
        const index = Object.keys(consts.NavNames).indexOf(key);
        navElements[index].classList.add("clicked");
      }

      this.$router
        .push({
          path: `/mainView/${navName}`,
        })
        .catch((error) => {
          console.log(
            "User is not permitted, NavigationRedirectedError.",
            error
          );
        });
    }
  }

  logoutUser(): any {
    API.clearJWT();
    this.$router.push({ name: "Login" });
  }

  created() {
    this.user = AuthModule.currentUser;
  }
}
