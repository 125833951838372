
import * as API from "../store/api";
import * as consts from "../consts";
import { Component, Vue } from "vue-property-decorator";
import AppModule from "../store/modules/app";
import AuthModule from "../store/modules/auth";
import * as helpers from "../helpers";
import validator from "validator";
import { EventBus, BusEvents } from "../helpers/eventbus";
import { User } from "../types";

@Component
export default class Login extends Vue {
  private username = "pplonka";
  private pass = "1234prym";
  loginError = "";
  max = 100;
  progressValue = 0;
  loginBtnKey = 0;
  newVersion = "v1.0.19";
  userExists = true;

  get appVersion() {
    return AppModule.appVersion;
  }

  get isOnline() {
    return helpers.onlineHelper.isOnline;
  }

  // TO-DO rest of the validations
  private validate() {
    if (!this.username) {
      return "Please fill in login";
    }
    if (!this.pass) {
      return "Please fill in password";
    }
    return "";
  }
  clearErrors() {
    this.loginError = "";
  }
  // TO-DO change event resets errors
  async login() {
    this.loginError = this.validate();
    if (this.loginError) {
      this.loginBtnKey++;
      return;
    }
    EventBus.$emit(BusEvents.LOGIN_PROGRESS, 10);

    const response = await API.loginUser({
      username: this.username,
      password: this.pass,
    });

    const user: User = response.data.user as User;

    if (
      response &&
      response.data &&
      response.data.success &&
      user !== undefined
    ) {
      console.log("Login.vue login Response", response);
      if (user.is_logged_in) {
        API.setJWT(user.token);
        AuthModule.setAuthenticated(user);

        console.log("usermodule", user.user_type);
        this.$router.push("mainView");
      } else {
        EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
        this.loginError = "Invalid login or password!";
        this.loginBtnKey++;
      }
    } else {
      if (response.data.errorCode === consts.ErrorCodes.TECHNICIAN_LOGGING_IN) {
        EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
        this.loginError = response.data.errorMessage;
        helpers.error("Technician is not authorized to log in");
        this.loginBtnKey++;
      } else if (
        response.data.errorCode === consts.ErrorCodes.CUSTOMER_USER_LOGGING_IN
      ) {
        EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
        this.loginError = response.data.errorMessage;
        helpers.error("Customer User is not authorized to log in");
        this.loginBtnKey++;
      } else {
        EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
        this.loginError = "Invalid login or password!";
        this.loginBtnKey++;
      }
    }
  }

  // TO-DO pokazać okno z pobraniem email, wyslac na niego nowy pass
  async reset() {
    // console.log("Reset pass");
    // if (!validator.isEmail(this.email)) {
    //   this.loginError = "Aby zresetować podaj poprawny adres email";
    //   return;
    // }
    // await API.reset(this.email)
    //   .then((response) => {
    //     console.log("Reset done", response);
    //     if (response.data.success === true) {
    //       helpers.info("Hasło zresetowane, sprawdź email");
    //     } else {
    //       helpers.error("Wystąpił błąd podczas resetowania hasła");
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     this.loginError = "Niepoprawny email lub hasło !";
    //   });
  }

  makeProgress(value: number) {
    this.progressValue = value;
  }
  refreshing = false;
  registration = null;
  updateExists = false;
  currentVersion = "";

  showRefreshUI(e) {
    console.log("Login showRefreshUI");
    this.registration = e.detail;
    this.updateExists = true;
  }
  refreshApp() {
    this.updateExists = false;

    if (!this.registration || !this.registration.waiting) {
      console.log("Login refreshApp !");
      return;
    }
    console.log("Login refreshApp post SKIP_WAITING");
    this.registration.waiting.postMessage("SKIP_WAITING");
  }

  async validateUsername() {
    console.log("validate");
    if (this.username !== "") {
      const response = await API.validateUsername(this.username);
      if (response.data.success === false && "error_code" in response.data) {
        console.log("Username exists");
        this.userExists = true;
      } else if (response.data.success === false) {
        helpers.error("Unexpected server error - cannot verify if user exists");
      } else if (response.data.success === true) {
        this.userExists = false;
      }
    }
  }

  mounted() {
    this.currentVersion = process.env.VUE_APP_VERSION;
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) {
          console.log("Login this.refreshing");
          return;
        }
        console.log("Login this.refreshing false, reload");
        this.refreshing = true;
        window.location.reload();
      });
    }
    EventBus.$on(BusEvents.LOGIN_PROGRESS, this.makeProgress);
  }
}
