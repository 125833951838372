
import { Component, Prop, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component({
  components: {
    TopPanel,
  },
})
export default class SuccessfullyCreated extends Vue {
  @Prop({ required: true, type: String }) breadcrumbs: string;
  @Prop({ required: false, default: null, type: Number }) userType: number;
  @Prop({ required: true, type: Number }) accountId: number;
  @Prop({ required: false, default: "accounts", type: String })
  routingFinish: string;
  @Prop({ required: false, default: null, type: Number })
  companyId: number;

  get accountTypeStr() {
    return helpers.getAccountTypeStr(this.userType);
  }

  routingHandle(path: string, companyId: number) {
    if (companyId === null) {
      this.$router.push({
        path: `/mainView/${path}`,
      });
    } else {
      this.$router.push({
        path: consts.RouterNames.COMPANY,
        name: consts.RouterNames.COMPANY,
        params: {
          company_id: this.companyId.toString(),
        },
      });
    }
  }

  goToProfile() {
    let profile = "";
    const type = this.userType;
    switch (type) {
      case consts.UserType.CUSTOMER_USER:
      case consts.UserType.CUSTOMER_ADMIN:
      case consts.UserType.FACILITY_MANAGER:
        profile = consts.RouterNames.CUSTOMER_USER;
        break;
      case consts.UserType.TGA_MANAGER:
        profile = consts.RouterNames.TGA_MANAGER;
        break;
      case consts.UserType.TECHNICIAN:
        profile = consts.RouterNames.TECHNICIAN;
        break;
      default:
        profile = consts.RouterNames.COMPANY;
        this.$router.push({
          path: profile,
          name: profile,
          params: {
            company_id: this.accountId.toString(),
          },
        });
        return;
    }
    this.$router.push({
      path: profile,
      name: profile,
      params: {
        user_id: this.accountId.toString(),
      },
    });
  }

  created() {
    const items = this.breadcrumbs.split(" > ");
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title: items[0],
          name: items[0].charAt(0).toLowerCase() + items[0].slice(1),
        },
        {
          title: items[1],
          name: "",
        },
      ],
    });
  }
}
