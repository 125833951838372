console.log("--- TYPES FILE LOADING ---");
import { SortDir } from "@/consts";

import { Enumify } from "enumify";

export class UserData {
  id: number;
  user: number;
  phone?: string;
  mobile_phone?: string;
  position?: string;
  user_type: number;

  constructor() {
    this.id = 0;
    this.user = 0;
    this.user_type = 0;
  }
}

export class Account {
  id: number;
  is_superuser?: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  mobile_phone: string;
  is_staff?: boolean;
  is_ative?: boolean;
  user_data?: UserData;
  userType?: number;

  constructor() {
    this.id = 0;
    this.username = "";
    this.first_name = "";
    this.last_name = "";
    this.email = "";
    this.phone = "";
    this.mobile_phone = "";
  }
}

export class AccountOnCard {
  name: string;
  id: number;
  username: string;
  user_data: UserData;
  company?: Company;

  constructor() {
    this.id = 0;
    this.username = "";
    this.name = "";
    this.user_data = new UserData();
  }
}

export class Address {
  id: number;
  street: string;
  house_no: string;
  apt_no?: string;
  city: string;
  postal_code: string;
  federal_state?: string;
  service_area?: string;
  country?: string;

  constructor() {
    this.id = 0;
    this.city = "";
    this.street = "";
    this.house_no = "";
    this.postal_code = "";
  }
}

export class Company {
  id: number;
  name: string;
  nip: string;
  phone: string;
  correspond_address: Address;
  nip_address?: Address;
  email: string;
  mobile_phone?: string;

  constructor() {
    this.id = 0;
    this.name = "";
    this.nip = "";
    this.phone = "";
    this.mobile_phone = "";
    this.correspond_address = new Address();
    this.email = "";
  }
}

export class Skill {
  id: number;
  name: string;
  code: string;
  trades?: number[];
  workType?: number;

  constructor() {
    this.id = 0;
    this.name = "";
    this.code = "";
  }
}

export class ServiceArea {
  id: number;
  name: string;
  code: string;

  constructor() {
    this.id = 0;
    this.name = "";
    this.code = "";
  }
}

export class Trade {
  id: number;
  name: string;
  code: string;
  color: string;

  constructor() {
    this.id = 0;
    this.name = "";
    this.code = "";
    this.color = "";
  }
}

export class WorkType {
  id: number;
  name: string;
  code: string;
  description: string;
  trade?: number;
  skills?: Skill[];

  constructor() {
    this.id = 0;
    this.name = "";
    this.code = "";
    this.description = "";
  }
}

export class Task {
  id: number;
  name: string;
  code: string;
  duration_time: string;
  task_list?: number;
  work_type?: number;

  constructor() {
    this.id = 0;
    this.name = "";
    this.code = "";
    this.duration_time = "";
  }
}

export class TaskList {
  id: number;
  name?: string;
  manual_total_time: string;
  work_type: number;
  task_type: number;
  tasks?: Task[];

  constructor() {
    this.id = 0;
    this.name = "";
    this.manual_total_time = "";
    this.work_type = 0;
    this.task_type = 0;
  }
}

export class DashboardEntity {
  id: number;
  number: string;
  name?: string;

  get getNumber() {
    return this.number;
  }
  set getNumber(value: string) {
    this.number = value;
  }
  get getDONEActionStatus(): Enumify {
    return null;
  }
  get getStatus() {
    return null;
  }
  set statusId(id: number) {
    console.log("");
  }
  update() {
    console.log("");
  }
}

export class ExtendedParam {
  fieldName: string;
  headerName: string;
  func?: any;
  getValues?: any;
  visible?: any;
  fieldType?: string;
  css?: string;
  columnCss?: string;
  options?: any[];
  multiSelectTrack?: string;
  valueSelected?: any;
  badgeVariant?: string;
  href?: any;
  bufferValue?: any;
  icon?: string;
  size?: string;
  headerCss?: string;
  sortField?: string;
  evaluate?: any;

  constructor(fieldName: string, type: string, headerName: string) {
    this.fieldName = fieldName;
    this.headerName = headerName;
  }
}

export class Client {
  id: number;
  name?: string;
  short_name?: string;
  code?: string;
  address_id?: number;
  address?: Address;
  nip?: string;
  active?: boolean;
  correspond_address?: string;

  contact_person_name?: string;
  contact_email?: string;
  contact_phone?: string;

  cplaces?: ConstructionPlace[];

  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    this.id = 0;
    this.name = "";
    this.code = "";
    this.cplaces = [];
    this.short_name = "";
    this.address_id = -1;
    this.address = new Address();
    this.active = true;
    this.contact_email = "";
    this.contact_person_name = "";
    this.contact_phone = "";
  }

  load() {
    this.address = null; //dictModule.addressById(this.address_id);
  }

  import?(obj: object) {
    Object.assign(this, obj);
  }
}

export class ConstructionPlace {
  id: number;
  name: string;
  short_name: string;
  symbol?: string;
  active?: boolean;
  client_id: number;

  constructor() {
    this.id = 0;
    this.name = "";
    this.short_name = "";
    this.symbol = "";
    this.active = true;
  }
}

export class Localization {
  id: number;
  name: string;
  code?: string;
}

export class UserRight {
  id: number;
  name: string;
  view_group?: string;
  symbol: string;
  description?: string;
}

export class UserSubmit {
  username: string;
  password: string;
}

export class UserGroup {
  id: number;
  name: string;
  type: number;
  index: number;
  symbol: string;
  group_ids: number[];
  right_ids: number[];
  rights?: UserRight[];
  right_symbols?: string[];
  description: string;

  load() {
    this.rights = [];
    this.right_ids.forEach((right_id) => {
      //const r = usersModule.getRightById(right_id);
      //this.rights.push(r);
    });
  }
}

export class User extends DashboardEntity {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  phone: string;
  mobile_phone: string;
  position?: string;
  token?: string;
  is_logged_in: boolean;
  created_date_time?: string;
  changed_date_time?: string;
  user_type?: number;
  user_type_str?: string;
  user_data?: UserData;
  initials: string;
  role_id?: number;
  group_ids?: number[];
  groups?: UserGroup[];
  right_ids?: number[];
  rights?: UserRight[];
  address?: Address;
  skills?: Skill[];
  service_areas?: ServiceArea[];

  public get fullName() {
    return this.first_name + " " + this.last_name;
  }
  get getNumber() {
    return this.fullName;
  }

  async update() {
    //await usersModule.updateUser(this);
  }

  constructor() {
    super();
    this.id = 0;
    this.email = "";
    this.username = "";
    this.phone = "";
    this.mobile_phone = "";
    this.first_name = "";
    this.last_name = "";
    this.token = "";
    this.is_logged_in = false;
    this.group_ids = [];
    this.groups = [];
    this.right_ids = [];
    this.rights = [];
    this.role_id = -1;
  }

  load(copy = true) {
    console.log("User load", this);
    this.rights = [];
    this.right_ids.forEach((rightId) => {
      //this.rights.push(usersModule.getRightById(rightId));
    });
    this.groups = [];
    this.group_ids.forEach((groupId) => {
      //const gr = usersModule.getGroupById(groupId);
      //gr.load();
      //this.groups.push(gr);
    });
  }
  hasRight(rightToCheck: UserRight) {
    //let result = false;
    this.right_ids.forEach((rId) => {
      //const right = usersModule.getRightById(rId);
      //if (right.id === rightToCheck.id) {
      //result = true;
      //}
    });
    //return result;
  }
}
export class UserResponse {
  user: User;
}
export class Profile {
  user?: User;
}

export class BreadcrumbItem {
  title: string;
  name?: string;
  link?: string | object;
  view?: AppView;
  class?: string;
  params?: any;
}

export class Breadcrumb {
  routePrefix: string;
  items: BreadcrumbItem[];
}

export interface AppView {
  broadcrumb?: Breadcrumb;
}

/**
 * IFilterabe - this interface is needed when we want to use [] accessing member by name
 */
export interface Filterable {
  [propName: string]: any;
}

export interface Filter {
  field: string;
  value: string;
}

export interface Sort {
  field: string;
  dir: SortDir;
}

export interface ListParams {
  pageNo: number;
  pageSize: number;
  curPageSize: number;
  rowsAllCount: number;
  pagesAllCount: number;
  filter: Filter;
  sort: Sort;
}

export interface RouterSettings {
  x: number;
  //last_view?: CalendarView;
}

export interface Settings {
  router_settings?: RouterSettings;
}

export class Material {
  id: number;
  name: string;
  description?: string;
  visible?: boolean; // cy pokazywac material na listach
}

export class Car {
  id: number;
  number: string;
  register_number?: string;
  default_user_id?: number;
  default_user?: User;
}

export class Message {
  id: number;
  title: string;
  content: string;
  created_date_time: string;
  group_id: number; // 0 - BADANIA, 1 - METROLOGIA
  message_type: number; // 0 - message, 1 - warning, 2 - error
  sender_id?: number; // id usera ktory wyslal wiadomosc jezeli to nie jest wiadomosc systemowa
  hash?: string;
  object_id?: number;
  object_type_id?: number;
  is_read?: boolean;
}

export class File {
  id: number;
  filename: string;
  fullpath?: string;
  uploading?: boolean;
  created_date_time?: string;
  description?: string;
  user_id?: number; // id usera ktory wgral plik
  group_id?: number; // opis urzadzenia, swiadectwo zdrowia itd
}
