
import { Component, Ref, Vue } from "vue-property-decorator";
import Avatar from "@/components/spec/Avatar.vue";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ProfileDetailFormLine from "@/components/spec/ProfileDetailFormLine.vue";
import TitleFormLine from "@/components/spec/TitleFormLine.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import * as userHelper from "@/helpers/user";
import { User } from "@/types";

@Component({
  components: {
    Avatar,
    ModalDelete,
    ProfileDetailFormLine,
    TitleFormLine,
    TopPanel,
  },
})
export default class TGAManagerDetail extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  user: User = new User();
  companyName = "";
  userId = Number(this.$route.params.user_id);
  userTypeStr =
    this.userId === consts.TGACompany.TGA_ADMIN_ID
      ? consts.UserTypeStr.TGA_ADMIN
      : consts.UserTypeStr.TGA_MANAGER;

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  get isManager() {
    return this.userTypeStr === consts.UserTypeStr.TGA_MANAGER;
  }

  get userType() {
    return consts.UserType.TGA_MANAGER;
  }

  async uploadUserAvatar(imageFile) {
    await userHelper.uploadUserAvatar(imageFile, this.user);
  }
  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.userId);
  }
  async removeUserAvatar() {
    await userHelper.removeUserAvatar(this.userId);
  }

  async handleUpdate($emit, value) {
    // TO-DO obsluzyc zmiane hasla, obecnie disabled
    console.log("emit", $emit, "value", value);
    const key = helpers.getKeyByValue(this.user, value);
    this.user[key] = $emit;
    console.log("edit user field", this.user[key], key, this.userId);
    const response = await API.saveUserField(this.user[key], key, this.userId);
    if (response.data.success === false) {
      if (response.data.error_code === consts.ErrorCodes.USER_EMAIL_EXISTS) {
        console.log("User email already exists");
        helpers.error("User email already exists, please use another");
        return;
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }
  }

  async handleUpdateTitleFormLine(user) {
    console.log("emit user", user);
    for (const value of [user.first_name, user.last_name, user.username]) {
      const key = helpers.getKeyByValue(user, value);
      await API.saveUserField(user[key], key, this.userId);
    }
  }

  routingHandle() {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${consts.NavNames.TGA_COMPANY}`,
    });
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteUser() {
    const response = await API.deleteUser(this.user.id);
    if (response.data.success) {
      helpers.info("User account has been deleted");
      this.$router.push({
        path: `/${consts.NavNames.MAIN_VIEW}/${consts.NavNames.TGA_COMPANY}`,
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async created() {
    this.companyName = consts.TGACompany.NAME;
    const response = await API.getTGAManager(this.userId);
    if (response.data.success) {
      this.user = response.data.user;
      this.user.phone = this.user.user_data.phone;
      this.user.mobile_phone =
        this.user.user_data.mobile_phone === ""
          ? "None"
          : this.user.user_data.mobile_phone;
      console.log("user", this.user, "userTypeStr", this.userTypeStr);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.ACCOUNTS.charAt(0).toUpperCase() +
            consts.NavNames.ACCOUNTS.slice(1),
          name: consts.NavNames.ACCOUNTS,
        },
        {
          title: this.user.first_name + " " + this.user.last_name,
          name: "",
        },
      ],
    });
  }
}
