
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ModalTask from "@/components/spec/ModalTask.vue";
import ModalTaskList from "@/components/spec/ModalTaskList.vue";
import ModalTaskListTimeLabel from "@/components/spec/ModalTaskListTimeLabel.vue";
import TaskCard from "@/components/spec/TaskCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import moment from "moment";
import { Trade, WorkType, TaskList } from "@/types";

@Component({
  components: {
    ModalDelete,
    ModalTask,
    ModalTaskList,
    ModalTaskListTimeLabel,
    TaskCard,
    TopPanel,
  },
})
export default class TaskListDetail extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  @Ref() readonly modalTask: ModalTask;
  @Ref() readonly modalTaskList: ModalTaskList;
  @Ref() readonly modalTaskListTimeLabel: ModalTaskListTimeLabel;
  tradeId = null;
  trade: Trade = new Trade();
  workTypeId = null;
  workType: WorkType = new WorkType();
  taskListId = null;
  taskList: TaskList = new TaskList();
  taskListTemplateType = consts.TemplateType.TASK_LIST;
  filteredTasks = [];
  filterInput = "";
  btnSave = 0;
  sumOfTasksTime = "";

  get singleTime() {
    return this.taskList.manual_total_time === null
      ? ""
      : moment.duration(this.taskList.manual_total_time).asMinutes();
  }

  getSumofTasksTime() {
    const taskDurationTimes = [];
    console.log(this.taskList, this.taskList.tasks);
    this.taskList.tasks.forEach((t) =>
      taskDurationTimes.push(moment.duration(t.duration_time).asMinutes())
    );
    this.sumOfTasksTime = taskDurationTimes.reduce((a, b) => a + b, 0);
  }

  async getTaskList() {
    const response = await API.getTaskLists(this.workTypeId);
    if (response.data.success) {
      this.taskList = response.data.task_lists.find(
        (tl) => tl.id === this.taskListId
      );
      console.log("task list", this.taskList);
      this.filteredTasks = this.taskList.tasks;
      this.getSumofTasksTime();
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  setBreadcrumbs() {
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TEMPLATES.charAt(0).toUpperCase() +
            consts.NavNames.TEMPLATES.slice(1),
          name: consts.NavNames.TEMPLATES,
        },
        {
          title:
            consts.RouterNames.TRADES.charAt(0).toUpperCase() +
            consts.RouterNames.TRADES.slice(1),
          name: consts.RouterNames.TRADES,
        },
        {
          title: this.trade.name,
          name: "templates/",
          params: {
            trade_id: this.trade.id.toString(),
          },
        },
        {
          title: this.workType.name,
          name: "templates/",
          params: {
            trade_id: this.trade.id.toString(),
            work_id: this.workType.id.toString(),
          },
        },
        {
          title: this.taskList.name,
          name: "",
        },
      ],
    });
  }

  filterTasks() {
    if (this.filterInput !== "") {
      const filteredTasks = this.taskList.tasks.filter((t) => {
        if (
          t.name.toLowerCase().includes(this.filterInput.toLowerCase()) ||
          t.code.toLowerCase().includes(this.filterInput.toLowerCase())
        )
          return true;
      });
      this.filteredTasks = filteredTasks;
    } else {
      this.filteredTasks = this.taskList.tasks;
    }
  }

  routingHandle() {
    this.$router.push({
      path: consts.RouterNames.WORK_TYPE,
      name: consts.RouterNames.WORK_TYPE,
      params: {
        trade_id: this.tradeId.toString(),
        work_type_id: this.workTypeId.toString(),
      },
    });
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteTaskList() {
    const response = await API.deleteTaskList(this.taskListId);
    if (response.data.success) {
      helpers.info("Task List has been deleted");
      this.$router.push({
        path: consts.RouterNames.WORK_TYPE,
        name: consts.RouterNames.WORK_TYPE,
        params: {
          trade_id: this.tradeId.toString(),
          work_type_id: this.workTypeId.toString(),
        },
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async showModalTaskCreate() {
    await this.modalTask.showModal();
  }

  async modalTaskOk() {
    await this.getTaskList();
  }

  async modalTaskCancel() {
    await this.getTaskList();
  }

  async showModalTaskListTimeLabel() {
    await this.modalTaskListTimeLabel.showModal(this.taskList);
  }

  async modalTaskListTimeLabelOk() {
    await this.getTaskList();
  }

  async modalTaskListTimeLabelCancel() {
    await this.getTaskList();
  }

  async showModalTaskList(taskList) {
    await this.modalTaskList.showModal(taskList);
  }

  async modalTaskListOk() {
    await this.getTaskList();
    this.setBreadcrumbs();
  }

  async modalTaskListCancel() {
    await this.getTaskList();
  }

  async created() {
    this.workTypeId = Number(this.$route.params.work_type_id);
    this.tradeId = Number(this.$route.params.trade_id);
    this.taskListId = Number(this.$route.params.task_list_id);
    await this.getTaskList();

    let response = await API.getWorkType(this.workTypeId);
    if (response.data.success) {
      this.workType = response.data.work_type;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    response = await API.getTrade(this.tradeId);
    if (response.data.success) {
      this.trade = response.data.trade;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    this.setBreadcrumbs();
  }
}
