
import { Component, Prop, Vue } from "vue-property-decorator";
import * as consts from "@/consts";

class CalendarEvent {
  color: "#000";
  date: Date;
}

@Component
export default class Calendar extends Vue {
  @Prop({ type: Object, default: null }) bus!: any;
  @Prop({ type: Date, default: () => new Date() }) currentDate: Date;
  @Prop({ type: Array, default: () => [] }) events: CalendarEvent[];
  @Prop({ type: Number, default: 500 }) maxWidth: number;
  @Prop({ type: String, default: "" }) linkToCalendar: string;

  //@Prop({ default: 150 }) width!: number;
  //@Prop({ default: 150 }) height!: number;

  current: Date = new Date();
  isReady = false;

  dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  goToCalendar() {
    this.$router.push({
      path: this.linkToCalendar,
    });
  }

  nextMonth() {
    this.current = this.addDays(this.current, 7);
  }
  prevMonth() {
    this.current = this.addDays(this.current, -7);
  }
  get getCurrent() {
    return this.monthNames[this.month] + " " + this.current.getFullYear();
  }

  // zwraca ilosc dni z poprzedniego mc ktore wystepuja w pierwszym tygodniu mc
  get getFirstWeekDaysCountFromPrevMonth() {
    const firstDayOfMonth = new Date(this.year, this.month, 1);
    return firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1;
  }

  get getLastWeekDaysCountFromNextMonth() {
    const day = new Date(this.year, this.month, this.monthDays);
    return day.getDay() === 0 ? 0 : 7 - day.getDay();
  }

  get year() {
    return this.current.getFullYear();
  }
  get month() {
    return this.current.getMonth();
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  get monthDays() {
    return new Date(this.year, this.month + 1, 0).getDate();
  }

  get weekDays() {
    const days = [];
    days.push(this.current.getDate());
    for (let i = 1; i < 7; i++) {
      days.push(this.addDays(this.current, i).getDate());
    }
    return days;
  }

  get dayName() {
    return (day) => {
      console.log(
        "day",
        day,
        new Date(this.year, this.month, day),
        new Date(this.year, this.month, day).getDay()
      );
      return this.dayNames[new Date(this.year, this.month, day).getDay()];
    };
  }

  get prevMonthDays() {
    if (this.month === 0) {
      return 31;
    } else {
      return new Date(this.year, this.month, 0).getDate();
    }
  }

  get ifEvent() {
    return (day) => {
      console.log("ifEvent", day);
      return true;
    };
  }

  get dayColorInt() {
    return (day) => {
      const dt = new Date(this.year, this.month, day);
      return dt.getDay() === 6 || dt.getDay() === 0
        ? "background: rgb(238,240,242) !important; border: 1px solid white;"
        : "";
    };
  }

  get eventStyle() {
    return (day) => {
      console.log("EventStyle ", day);
      return "color: red";
    };
  }

  // konwertuje date eventu na kolumne
  get getEventColumn() {
    return (event) => {
      return event.date.getDay();
    };
  }

  get dayEvents() {
    return (day) => {
      const events = this.events.filter(
        (e) => e.date.getDate() === day && e.date.getMonth() === this.month
      );
      return events;
    };
  }

  mounted() {
    this.current = this.currentDate;
    this.isReady = true;
  }
}
