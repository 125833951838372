import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

import * as deps from "./dependencies";

Vue.config.devtools = true;

new Vue({
  router,
  store,
  beforeCreate() {
    console.log(
      "------------------------- beforeCreate INITIALISE store -------------------------------"
    );

    deps
      .loadFromDB()
      .then(() => {
        //deps.loadStore();
        //deps.saveToDB();
      })
      .catch((err) => {
        console.error(err);
      });

    //this.$store.commit("loadLocalStorage");
    /* TEST LOCAL STORAGE QUOTA
    if (localStorage && !localStorage.getItem("size")) {
      let i = 0;
      try {
        // Test up to 10 MB
        for (i = 250; i <= 1000000; i += 250) {
          const arr = new Array(i * 1024 + 1).join("a");
          localStorage.setItem("test", arr);
          console.log("test", arr.length);
        }
      } catch (e) {
        localStorage.removeItem("test");
        localStorage.setItem("size", (i - 250).toString());
      }
    }*/ console.log(
      "-------------------------- end INITIALISE ----------------------------"
    );
  },
  render: (h) => h(App),
  created() {
    const watchers = this.$store._vm._computedWatchers;
    Object.keys(watchers).forEach((key) => {
      watchers[key].watcherName = key;
    });
    console.log("Watchers:", watchers);
  },
}).$mount("#app");
