
import { Component, Prop, Vue } from "vue-property-decorator";
import { Task } from "@/types";
import * as consts from "@/consts";
import moment from "moment";

@Component({
  components: {},
})
export default class TaskListCard extends Vue {
  @Prop() taskList;
  @Prop({ type: Number, required: true }) tradeId;
  @Prop({ type: Number, required: true }) workTypeId;

  tasks: Task[] = [];
  taskTypes = [
    consts.TaskTypeStr.MAINTENANCE,
    consts.TaskTypeStr.REPAIR,
    consts.TaskTypeStr.INSTALLATION,
    consts.TaskTypeStr.CUSTOM,
  ];

  get moreTasksCount() {
    return this.tasks.length - 2;
  }

  get totalTime() {
    if (this.taskList.manual_total_time !== null) {
      const manualTotalTime = moment
        .duration(this.taskList.manual_total_time)
        .asMinutes();
      return (
        Math.floor(manualTotalTime / 60) +
        " hrs " +
        (manualTotalTime % 60) +
        " min"
      );
      // @TO-DO show manual total time properly
    } else {
      const taskDurationTimes = [];
      this.taskList.tasks.forEach((t) =>
        taskDurationTimes.push(moment.duration(t.duration_time).asMinutes())
      );
      const sum = taskDurationTimes.reduce((a, b) => a + b, 0);
      return Math.floor(sum / 60) + " hrs " + (sum % 60) + " min";
    }
  }

  goToTaskList() {
    this.$router.push({
      path: consts.RouterNames.TASK_LIST,
      name: consts.RouterNames.TASK_LIST,
      params: {
        trade_id: this.tradeId.toString(),
        work_type_id: this.workTypeId.toString(),
        task_list_id: this.taskList.id.toString(),
      },
    });
  }

  async created() {
    this.tasks = this.taskList.tasks;
  }
}
