
import { Vue, Component, Prop } from "vue-property-decorator";
import { User } from "@/types";

@Component
export default class TitleFormLine extends Vue {
  @Prop({ required: true }) user!: User;
  @Prop({ default: true }) editable!: boolean;

  edit = false;
}
