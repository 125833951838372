
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalSkill from "@/components/spec/ModalSkill.vue";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

enum FilterTypes {
  INPUT = "input",
  TRADE = "trade",
}

@Component({
  components: {
    ModalSkill,
    ModalDelete,
    TopPanel,
  },
})
export default class Skills extends Vue {
  @Ref() readonly modalSkill: ModalSkill;
  @Ref() readonly modalDelete: ModalDelete;
  tradeSkills = [];
  filteredTradeSkillsArray = [];
  filterInput = "";
  trade = [];
  tradeId = null;
  trades = [];
  filterButtons = [
    { type: FilterTypes.INPUT, value: "", selected: false },
    { type: FilterTypes.TRADE, value: {}, selected: false },
  ];
  filterTypeTrade = FilterTypes.TRADE;
  isSelectionMode = false;
  skillIds = [];
  skillTemplateType = [consts.TemplateType.SKILL, consts.TemplateTypeStr.SKILL];

  get filterButtonsTrue() {
    return this.filterButtons.filter((fb) => fb.selected === true);
  }

  get isFiltered() {
    for (const fb of this.filterButtons) {
      if (fb.selected == true) {
        return true;
      }
    }
    return false;
  }

  get tradeSkillsSelected() {
    return this.tradeSkills.filter((ts) => ts.selected === true);
  }

  async getTradeSkills() {
    const response = await API.getTradeSkills();
    if (response.data.success) {
      this.tradeSkills = response.data.trade_skills.map((ts) => ({
        ...ts,
        selected: false,
      }));
      this.filteredTradeSkillsArray = this.tradeSkills;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  getTradeColor(tradeCode) {
    return this.trades.find((t) => t.code === tradeCode).color;
  }

  toggleSelectMultiple() {
    if (this.isSelectionMode === true) {
      this.tradeSkills = this.tradeSkills.map((ts) => ({
        ...ts,
        selected: false,
      }));
      this.filteredTradeSkillsArray = this.filteredTradeSkillsArray.map(
        (fts) => ({
          ...fts,
          selected: false,
        })
      );
    }
    return (this.isSelectionMode = !this.isSelectionMode);
  }

  routingHandle(path: string) {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
    });
  }

  selectTrade(e) {
    this.trade = e;
    this.tradeId = this.trade["id"];
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === FilterTypes.TRADE) {
        return {
          ...fb,
          value: { code: this.trade["code"], name: this.trade["name"] },
          selected: true,
        };
      }
      return fb;
    });
    this.filterTradeSkills();
  }

  filterTradeSkills() {
    let filteredTradeSkillsArray = this.tradeSkills;
    if (this.filterInput !== "" || this.tradeId !== null) {
      if (this.filterInput !== "") {
        this.filterButtons = this.filterButtons.map((fb) => {
          if (fb.type === FilterTypes.INPUT) {
            return { ...fb, value: this.filterInput, selected: true };
          }
          return fb;
        });
        filteredTradeSkillsArray = filteredTradeSkillsArray.filter((ts) => {
          if (
            ts.skill.code
              .toLowerCase()
              .includes(this.filterInput.toLowerCase()) ||
            ts.skill.name.toLowerCase().includes(this.filterInput.toLowerCase())
          ) {
            return true;
          }
        });
        this.filteredTradeSkillsArray = filteredTradeSkillsArray;
      }
      if (this.tradeId !== null) {
        filteredTradeSkillsArray = filteredTradeSkillsArray.filter((ts) => {
          if (ts.trade.id === this.tradeId) {
            return true;
          }
        });
        this.filteredTradeSkillsArray = filteredTradeSkillsArray;
      }
    } else {
      this.filteredTradeSkillsArray = this.tradeSkills;
      this.filterButtons = this.filterButtons.map((fb) => {
        if (fb.type === FilterTypes.INPUT) {
          return { ...fb, value: "", selected: false };
        }
        return fb;
      });
    }
    this.$forceUpdate();
  }

  deleteOneFilter(filterType) {
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === filterType) {
        if (fb.type === FilterTypes.TRADE) {
          return { ...fb, value: {}, selected: false };
        } else {
          return { ...fb, value: "", selected: false };
        }
      }
      return fb;
    });
    if (filterType === FilterTypes.TRADE) {
      this.trade = [];
      this.tradeId = null;
    } else {
      this.filterInput = "";
    }
    this.filterTradeSkills();
  }

  clearFilters() {
    this.filterInput = "";
    this.trade = [];
    this.tradeId = null;
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === FilterTypes.TRADE) {
        return { ...fb, value: {}, selected: false };
      } else {
        return { ...fb, value: "", selected: false };
      }
    });
    this.filterTradeSkills();
  }

  async showModalSkillCreate() {
    await this.modalSkill.showModal();
  }

  async modalSkillOk() {
    await this.getTradeSkills();
  }

  async modalSkillCancel() {
    await this.getTradeSkills();
  }

  async showModalSkill(tradeskill) {
    const skillTradeSkills = this.tradeSkills.filter(
      (ts) => ts.skill.id === tradeskill.skill.id
    );
    if (skillTradeSkills.length > 1) {
      const trades = [];
      skillTradeSkills.forEach((sts) => trades.push(sts.trade));
      tradeskill["trades"] = trades;
    }
    await this.modalSkill.showModal(tradeskill);
  }

  toggleTradeSkillSelected(tradeSkill) {
    this.tradeSkills = this.tradeSkills.map((ts) => {
      if (ts.id === tradeSkill.id) {
        return { ...ts, selected: !ts.selected };
      }
      return ts;
    });
    this.filteredTradeSkillsArray = this.filteredTradeSkillsArray.map((fts) => {
      if (fts.id === tradeSkill.id) {
        return { ...fts, selected: !fts.selected };
      }
      return fts;
    });
  }

  showModalDelete() {
    this.skillIds = [];
    this.tradeSkillsSelected.forEach((tss) => {
      if (!this.skillIds.includes(tss.skill.id)) {
        this.skillIds.push(tss.skill.id);
      }
    });
    this.modalDelete.showModal();
  }

  async deleteSkills() {
    const response = await API.deleteSkills(this.skillIds);
    if (response.data.success) {
      helpers.info("Skill(s) have been deleted");
      this.skillIds = [];
      await this.getTradeSkills();
      this.filterTradeSkills();
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    await this.getTradeSkills();
  }

  async created() {
    const response = await API.getTrades();
    if (response.data.success) {
      this.trades = response.data.trades;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    await this.getTradeSkills();
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TEMPLATES.charAt(0).toUpperCase() +
            consts.NavNames.TEMPLATES.slice(1),
          name: consts.NavNames.TEMPLATES,
        },
        {
          title:
            consts.RouterNames.SKILLS.charAt(0).toUpperCase() +
            consts.RouterNames.SKILLS.slice(1),
          name: "",
        },
      ],
    });
  }
}
