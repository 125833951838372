
import { Component, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ChooseUser from "@/components/spec/ChooseUser.vue";
import SuccessfullyCreated from "@/components/spec/SuccessfullyCreated.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { User } from "@/types";

@Component({
  components: {
    ChooseUser,
    SuccessfullyCreated,
    TopPanel,
  },
})
export default class CreateUser extends Vue {
  saved = false;
  btnSave = 0;
  routingFinish = "";
  personalId = null;
  userTypeStr = "";
  userType = 0;
  userTypeChosen = false;
  selected = null;
  companyIdFromRoute = null;
  companyId = null;
  company = [];
  companies = [];
  user: User = new User();
  userExists = false;

  get isAccountDetailsFilled() {
    return this.user.username !== "" && this.companyId !== null;
  }

  get isMasterDataFilled() {
    return (
      this.user.first_name !== "" &&
      this.user.email !== "" &&
      this.user.phone !== "" &&
      this.user.last_name !== ""
    );
  }

  get isSaveEnabled() {
    return (
      this.isAccountDetailsFilled && this.isMasterDataFilled && !this.userExists
    );
  }

  async saveUser() {
    this.user["userType"] = this.userType;
    console.log("user to save", this.user);
    const response = await API.saveUser(this.user, this.companyId);

    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.USER_EMAIL_EXISTS
    ) {
      console.log("User email already exists");
      helpers.error("User email already exists, please use another");
      this.btnSave++;
      return;
    }
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.USER_EXISTS
    ) {
      console.log("Username already exists");
      helpers.error("Username already exists, please use another");
      this.btnSave++;
      return;
    }
    if (response.data.success) {
      this.personalId = response.data.user.id;
      this.saved = true;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  chosenUser($emit) {
    this.userTypeStr = $emit;
    this.$emit("chosen", this.userTypeStr);
    if (this.userTypeStr === consts.UserTypeStr.TGA_MANAGER) {
      this.$router.push({
        path: `/${consts.NavNames.MAIN_VIEW}/${consts.RouterNames.TGA_MANAGER}`,
        name: `${consts.RouterNames.TGA_MANAGER}New`,
      });
    } else {
      this.setBreadcrumb();
      this.userType = helpers.getUserType(this.userTypeStr);
      this.userTypeChosen = true;
    }
  }

  async validateUsername() {
    if (this.user.username !== "") {
      const response = await API.validateUsername(this.user.username);
      if (response.data.success === false && "error_code" in response.data) {
        console.log("Username already exists");
        this.userExists = true;
      } else if (response.data.success === false) {
        helpers.error(
          "Unexpected server error - cannot verify if user already exists"
        );
      } else if (response.data.success === true) {
        this.userExists = false;
      }
    }
  }

  selectCompany(e: any) {
    this.company = e;
    this.companyId = this.company["id"];
    console.log("selected", this.company);
  }

  setBreadcrumb() {
    const items = [
      {
        title:
          consts.NavNames.ACCOUNTS.charAt(0).toUpperCase() +
          consts.NavNames.ACCOUNTS.slice(1),
        name: "accounts",
      },
    ];
    if (this.userTypeStr === "") {
      items.push({
        title: consts.BreadcrumbTitles.NEW_USER,
        name: "",
      });
    } else {
      items.push({
        title: consts.BreadcrumbTitles.CREATE_USER_PREFIX + this.userTypeStr,
        name: "",
      });
    }
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: items,
    });
  }

  async created() {
    API.getPersonalId().then((res) => {
      this.personalId = res.data.id;
    });
    this.routingFinish = this.$route.params.routingFinish;
    if ("company_id" in this.$route.params) {
      this.companyIdFromRoute = Number(this.$route.params.company_id);
      this.companyId = this.companyIdFromRoute;
      const response = await API.getCompany(this.companyId);
      if (response.data.success) {
        this.company = response.data.company;
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    } else {
      const response = await API.getCompanies();
      if (response.data.success) {
        this.companies = response.data.companies;
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }
    this.setBreadcrumb();
  }
}
