import Vue from "vue";
import filters from "./helpers/filters";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import * as API from "@/store/api";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { EventBus, BusEvents } from "@/helpers/eventbus";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCog,
  faEnvelope,
  faFilter,
  faHome,
  faListAlt,
  faPlus,
  faQuestion,
  faShoppingBasket,
  faSort,
  faUserCircle,
  faCircle,
  faUserCog,
  faUserSecret,
  faVial,
  faPlusSquare,
  faMinusSquare,
  faTrashAlt,
  faHandshake,
  faFilePowerpoint,
  faTimes,
  faCheck,
  faSignature,
  faEdit,
  faEye,
  faHistory,
  faRedoAlt,
  faShieldAlt,
  faPlayCircle,
  faSignOutAlt,
  faPowerOff,
  faTable,
  faWindowMaximize,
  faWindowMinimize,
  faTools,
  faExclamationTriangle,
  faExclamationCircle,
  faSave,
  faFilePdf,
  faClock,
  faDiagnoses,
  faArrowsAlt,
  faMinusCircle,
  faClone,
  faDotCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import smoothscroll from "smoothscroll-polyfill";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export async function loadFromDB() {
  console.log(
    "%%%%%%%%%%%%%%%%%% loading from DB to store %%%%%%%%%%%%%%%%%%%%%"
  );
}

Vue.config.productionTip = false;
Vue.use(BootstrapVue, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"],
});
Vue.use(BootstrapVueIcons);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("multiselect", Multiselect);
Vue.component("VueSlider", VueSlider);
Vue.component("VSwatches", VSwatches);

library.add(
  faUserSecret,
  faHome,
  faCalendarAlt,
  faShoppingBasket,
  faUserCog,
  faListAlt,
  faEnvelope,
  faCog,
  faUserCircle,
  faCircle,
  faQuestion,
  faVial,
  faChevronRight,
  faPlus,
  faChevronLeft,
  faChevronDown,
  faFilter,
  faSort,
  faPlusSquare,
  faMinusSquare,
  faTrashAlt,
  faHandshake,
  faFilePowerpoint,
  faTimes,
  faCheck,
  faSignature,
  faEdit,
  faEye,
  faHistory,
  faRedoAlt,
  faShieldAlt,
  faPlayCircle,
  faSignOutAlt,
  faPowerOff,
  faTable,
  faWindowMaximize,
  faWindowMinimize,
  faTools,
  faExclamationTriangle,
  faExclamationCircle,
  faSave,
  faFilePdf,
  faClock,
  faDiagnoses,
  faArrowsAlt,
  faMinusCircle,
  faClone,
  faDotCircle,
  faPlusCircle
);

Vue.prototype.$sync = function (key: string, value: any) {
  console.log("Sync key", key, "value", value);
  this.$emit(`update:${key}`, value);
};

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

Vue.prototype.window = window;
Vue.prototype.events = new Vue();

smoothscroll.polyfill();

console.log("Deps loaded");
