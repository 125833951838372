
import { Component, Vue } from "vue-property-decorator";
import NavView from "@/components/spec/NavView.vue";
import Avatar from "@/components/spec/Avatar.vue";
import AvatarCard from "@/components/spec/AvatarCard.vue";
import * as userHelper from "@/helpers/user";
import Calendar from "@/components/spec/Calendar.vue";
import CalendarWeek from "@/components/spec/CalendarWeek.vue";

@Component({
  components: {
    NavView,
    Avatar,
    AvatarCard,
    Calendar,
    CalendarWeek,
  },
})
export default class Test extends Vue {
  height = 128;
  userHelper = userHelper;

  get getEvents() {
    return [
      {
        color: "#000",
        date: new Date(2023, 3, 15),
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 15),
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 15),
      },
      {
        color: "#21badc",
        date: new Date(2023, 3, 25),
      },
      {
        color: "#000",
        date: new Date(2023, 3, 16),
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 16),
      },
      {
        color: "#21badc",
        date: new Date(2023, 3, 17),
      },
    ];
  }
}
