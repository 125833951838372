import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { UserType } from "@/consts";
import { isUserLoggedIn, isUserPermitted } from "@/helpers";
import Login from "@/views/Login.vue";
import MainView from "@/views/MainView.vue";
import Dashboard from "@/views/main-view/Dashboard.vue";
import Accounts from "@/views/main-view/Accounts.vue";
import Companies from "@/views/main-view/Companies.vue";
import CreateCompany from "@/views/main-view/CreateCompany.vue";
import CreateUser from "@/views/main-view/CreateUser.vue";
import CustomerCompanyDetail from "@/views/main-view/CustomerCompanyDetail.vue";
import CustomerUserDetail from "@/views/main-view/CustomerUserDetail.vue";
import NewTechnician from "@/views/main-view/NewTechnician.vue";
import NewTGAManager from "@/views/main-view/NewTGAManager.vue";
import Services from "@/views/main-view/Services.vue";
import Skills from "@/views/main-view/Skills.vue";
import TaskListDetail from "@/views/main-view/TaskListDetail.vue";
import TGAManagerDetail from "@/views/main-view/TGAManagerDetail.vue";
import Technician from "@/views/main-view/Technician.vue";
import TechnicianCalendar from "@/views/main-view/TechnicianCalendar.vue";
import Technicians from "@/views/main-view/Technicians.vue";
import Templates from "@/views/main-view/Templates.vue";
import Trades from "@/views/main-view/Trades.vue";
import Test from "@/views/Test.vue";
import TGACompanyDetail from "@/views/main-view/TGACompanyDetail.vue";
import TradeDetail from "@/views/main-view/TradeDetail.vue";
import WorkTypeDetail from "@/views/main-view/WorkTypeDetail.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/test",
    name: "test",
    component: Test,
  },
  {
    path: "/mainView",
    name: "mainView",
    component: MainView,
    children: [
      { path: "", redirect: "dashboard" },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "tgaManager",
        name: "tgaManagerNew",
        component: NewTGAManager,
        props: true,
        meta: {
          onlyTGAAdmin: true,
        },
      },
      {
        path: "tgaManager/:user_id",
        name: "tgaManager",
        component: TGAManagerDetail,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "technicians",
        name: "technicians",
        component: Technicians,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "technician",
        name: "technicianNew",
        component: NewTechnician,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "technician/:user_id",
        name: "technician",
        component: Technician,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "technicianCalendar/:user_id",
        name: "technicianCalendar",
        component: TechnicianCalendar,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "accounts",
        name: "accounts",
        component: Accounts,
        props: true,
      },
      {
        path: "companies",
        name: "companies",
        component: Companies,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "company",
        name: "companyNew",
        component: CreateCompany,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "user",
        name: "userNew",
        component: CreateUser,
        props: true,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "customerUser/:user_id",
        name: "customerUser",
        component: CustomerUserDetail,
        props: true,
      },
      {
        path: "company/:company_id",
        name: "company",
        component: CustomerCompanyDetail,
        props: true,
      },
      {
        path: "tgaCompany",
        name: "tgaCompany",
        component: TGACompanyDetail,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "services",
        name: "services",
        component: Services,
      },
      {
        path: "templates",
        name: "templates",
        component: Templates,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "trades",
        name: "trades",
        component: Trades,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "templates/trades/:trade_id",
        name: "trade",
        component: TradeDetail,
        props: true,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "templates/trades/:trade_id/:work_type_id",
        name: "workType",
        component: WorkTypeDetail,
        props: true,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "templates/trades/:trade_id/:work_type_id/:task_list_id",
        name: "taskList",
        component: TaskListDetail,
        props: true,
        meta: {
          onlyTGA: true,
        },
      },
      {
        path: "skills",
        name: "skills",
        component: Skills,
        meta: {
          onlyTGA: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !isUserLoggedIn()) {
    next({ name: "Login" });
  } else {
    if (
      to.meta.onlyTGA &&
      !isUserPermitted([UserType.TGA_ADMIN, UserType.TGA_MANAGER])
    ) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
});

export default router;
