
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import moment from "moment";
import { Task } from "@/types";

@Component({
  components: {
    ModalDelete,
  },
})
export default class ModalWorkType extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  @Prop({ type: Number, required: true }) taskListId;
  task: Task = new Task();
  show = false;
  btnSave = 0;
  isNew = false;
  codeExists = false;
  isNumeric = true;
  durationTimeParsedInt = null;
  durationTimeMins = null;
  taskTemplateType = [consts.TemplateType.TASK, consts.TemplateTypeStr.TASK];

  showModal(task: Task = null) {
    if (task === null) {
      this.isNew = true;
      this.task = new Task();
    } else {
      this.task = task;

      this.durationTimeMins = moment
        .duration(this.task.duration_time)
        .asMinutes();
    }
    this.show = true;
  }

  async validateTemplateCode() {
    if (this.task.code !== "") {
      const response = await API.validateTemplateCode(
        this.task.code,
        consts.TemplateType.TASK
      );
      if (response.data.success === false && "error_code" in response.data) {
        console.log("Task code exists");
        this.codeExists = true;
      } else if (response.data.success === false) {
        helpers.error(
          "Unexpected server error - cannot verify if task code exists"
        );
      } else if (response.data.success === true) {
        this.codeExists = false;
      }
    } else {
      this.codeExists = false;
    }
  }

  validateIsNumeric(time) {
    if (time !== "") {
      if (typeof time != "string") {
        this.isNumeric = false;
      } else {
        this.durationTimeParsedInt = parseInt(time);
        this.isNumeric = !isNaN(this.durationTimeParsedInt);
      }
      if (this.isNumeric === false) {
        helpers.error("Please enter only numeric characters");
      }
    } else {
      this.isNumeric = true;
    }
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteTask() {
    const response = await API.deleteTask(this.task.id);
    if (response.data.success) {
      helpers.info("Task has been deleted");
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.hideModal();
  }

  async saveModal() {
    this.task.task_list = this.taskListId;
    if (this.isNew === true) {
      if (this.task.duration_time !== "") {
        this.task.duration_time = this.durationTimeParsedInt.toString();
      } else {
        this.task.duration_time = "";
      }
    } else {
      if (this.durationTimeMins !== "") {
        this.task.duration_time = this.durationTimeParsedInt.toString();
      } else {
        this.task.duration_time = "";
      }
    }
    const response = await API.saveTask(this.task);
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.CODE_EXISTS
    ) {
      console.log("Task code already exists");
      helpers.error("Task code already exists, please enter another");
      this.btnSave++;
      return;
    } else if (response.data.success === false) {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
