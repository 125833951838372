
import { Component, Prop, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { ServiceArea } from "@/types";

const TOTAL_SERVICE_AREAS = 194;

@Component({
  components: {
    TopPanel,
  },
})
export default class ServiceAreas extends Vue {
  @Prop({ required: false, default: [] }) selectedServiceAreas;
  serviceAreas = [];
  paginatedServiceAreas = [];
  filterInput = "";
  maxServiceAreas = consts.MAX_SERVICE_AREAS;
  pageIndexView = 0;
  isAllServiceAreasSelected = true;

  chunkArray(array, chunkSize) {
    const chunkededArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunkededArray.push(chunk);
    }
    return chunkededArray;
  }

  getPaginated() {
    if (this.filterInput !== "") {
      const filteredServiceAreas = this.serviceAreas.filter((sa) => {
        if (
          sa.name.toLowerCase().includes(this.filterInput.toLowerCase()) ||
          sa.code.toLowerCase().includes(this.filterInput.toLowerCase())
        )
          return true;
      });
      this.paginatedServiceAreas = this.chunkArray(filteredServiceAreas, 32);
      this.paginatedServiceAreas = this.paginatedServiceAreas.map((nested) =>
        this.chunkArray(nested, 8)
      );
    } else {
      this.paginatedServiceAreas = this.chunkArray(this.serviceAreas, 32);
      this.paginatedServiceAreas = this.paginatedServiceAreas.map((nested) =>
        this.chunkArray(nested, 8)
      );
    }
  }

  filterServiceAreas() {
    this.getPaginated();
  }

  toggleSelectAllServiceAreas() {
    this.serviceAreas = this.serviceAreas.map((area) => ({
      ...area,
      selected: this.isAllServiceAreasSelected,
    }));
    this.getPaginated();
    if (this.isAllServiceAreasSelected === false) {
      this.selectedServiceAreas = [];
    } else {
      this.selectedServiceAreas = this.serviceAreas;
    }
    this.$emit("updateServiceAreas", this.selectedServiceAreas);
  }

  toggleSelectServiceArea(serviceArea) {
    const isFound = this.selectedServiceAreas.some((sa) => {
      if (sa.id === serviceArea.id) {
        return true;
      }
      return false;
    });
    if (isFound === false) {
      if (this.selectedServiceAreas.length >= this.maxServiceAreas) {
        helpers.error("The maximum number of selections has been reached");
      } else {
        this.serviceAreas = this.serviceAreas.map((sa) => {
          if (sa.id === serviceArea.id) {
            return { ...sa, selected: true };
          }
          return sa;
        });
        this.selectedServiceAreas.push({ ...serviceArea, selected: true });
        this.getPaginated();
        this.$emit("updateServiceAreas", this.selectedServiceAreas);
      }
    } else {
      {
        this.serviceAreas = this.serviceAreas.map((sa) => {
          if (sa.id === serviceArea.id) {
            return { ...sa, selected: false };
          }
          return sa;
        });
        this.selectedServiceAreas = this.selectedServiceAreas.filter(
          (sa) => sa.id != serviceArea.id
        );
      }
      this.getPaginated();
      this.$emit("updateServiceAreas", this.selectedServiceAreas);
    }
  }

  async created() {
    this.isAllServiceAreasSelected =
      this.selectedServiceAreas.length === 0 ||
      this.selectedServiceAreas.length === TOTAL_SERVICE_AREAS;
    const response = await API.getServiceAreas();
    if (response.data.success) {
      if (this.isAllServiceAreasSelected === true) {
        this.serviceAreas = response.data.service_areas.map((area) => ({
          ...area,
          selected: true,
        }));
        this.selectedServiceAreas = this.serviceAreas;
      } else {
        this.serviceAreas = response.data.service_areas.map((area) => ({
          ...area,
          selected: false,
        }));
        this.selectedServiceAreas = this.selectedServiceAreas.map((area) => ({
          ...area,
          selected: true,
        }));
        for (const ssa of this.selectedServiceAreas) {
          const isFound = this.serviceAreas.some((sa) => {
            if (sa.id === ssa.id) {
              return true;
            }
            return false;
          });
          if (isFound === true) {
            this.serviceAreas = this.serviceAreas.map((sa) => {
              if (sa.id === ssa.id) {
                return { ...sa, selected: true };
              }
              return sa;
            });
          }
        }
      }
      this.$emit("updateServiceAreas", this.selectedServiceAreas);
      this.getPaginated();
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }
}
