console.log("--- CONSTS FILE LOADING ---");
import { Enumify } from "enumify";

export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";
export const DB_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DB_DATE_FORMAT = "YYYY-MM-DD";

export const NEW_ID_START = 100000;
export const TEST_ID_FIRST = 200000;
export const TEST_ID_SECOND = 200001;
export const TEST_ID_THIRD = 200002;

export const TEMP_SERVER_ID = 500000;
export const MAX_LEVELS = 4;
export const MAX_SERVICE_AREAS = 30;

export const SERVER_COMMUNICATION_ERROR =
  "A server communication error has occurred. Please try again later.";

export default interface EnumComplex {
  id: number;
  name: string;
  symbol?: string;
}

export const Months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listapad",
  "Grudzień",
];

export enum ModalEvents {
  CANCEL_EVENT = "modal_cancel",
  OK_EVENT = "modal_ok",
  TIME_CHANGED_EVENT = "time_changed",
}

export enum Events {
  OK_EVENT = "ok",
  CANCEL_EVENT = "cancel",
  UPLOAD_EVENT = "upload",
  REMOVE_EVENT = "remove",
}

export enum PageMode {
  PREVIEW = "preview",
  NEW = "new",
  EDIT = "edit",
}

export enum CalendarViewType {
  DAY = "day",
  MONTH = "month",
  PERSON_DAY = "person_day",
  VISIT = "visit",
}

export enum ErrorCodes {
  USER_EMAIL_EXISTS = 1,
  USER_EXISTS = 2,
  COMPANY_NIP_EXISTS = 3,
  OBJECT_NOT_FOUND = 4,
  WRONG_PASSWORD = 5,
  CODE_EXISTS = 6,
  TECHNICIAN_LOGGING_IN = 7,
  TASK_LIST_EXISTS = 8,
  CUSTOMER_USER_LOGGING_IN = 9,
  COLOR_EXISTS = 10,
  COLOR_IS_USED = 11,
}

export enum TGACompany {
  ID = 1,
  NAME = "TGA GmbH",
  TGA_ADMIN_ID = 1,
}

export enum UserType {
  NOT_DEFINED = 0,
  CUSTOMER_USER = 1,
  TECHNICIAN = 2,
  TGA_MANAGER = 3,
  TGA_ADMIN = 4,
  CUSTOMER_ADMIN = 5,
  FACILITY_MANAGER = 6,
}

export enum AccountType {
  USER = 0,
  COMPANY = 1,
}

export enum TemplateType {
  TRADE = 0,
  WORK_TYPE = 1,
  TASK_LIST = 2,
  TASK = 3,
  SKILL = 4,
}

export enum TemplateTypeStr {
  TRADE = "Trade",
  WORK_TYPE = "Service",
  TASK_LIST = "Task List",
  TASK = "Task",
  SKILL = "Skill",
}

export enum TaskTypeStr {
  MAINTENANCE = "Maintenance",
  REPAIR = "Repair",
  INSTALLATION = "Installation",
  CUSTOM = "Custom",
}

export enum UserTypeStr {
  NOT_DEFINED = "NotDefined",
  CUSTOMER_USER = "Customer User",
  TECHNICIAN = "Technician",
  TGA_MANAGER = "TGA Manager",
  TGA_ADMIN = "TGA Admin",
  CUSTOMER_ADMIN = "Customer Admin",
  FACILITY_MANAGER = "Facility Manager",
}

export enum NavNames {
  DASHBOARD = "dashboard",
  ACCOUNTS = "accounts",
  TECHNICIANS = "technicians",
  TECHNICIAN = "technician",
  TECHNICIAN_CALENDAR = "technicianCalendar",
  SERVICES = "services",
  TEMPLATES = "templates",
  NOTIFICATIONS = "notifications",
  ACCOUNT = "account",
  TGA_COMPANY = "tgaCompany",
  TGA_ADMIN = "tgaAdmin",
  TGA_MANAGERS = "tgaManagers",
  CUSTOMER_COMPANIES = "companies",
  CUSTOMER_ADMINS = "customerAdmins",
  CUSTOMER_USERS = "customerUsers",
  FACILITY_MANAGERS = "facilityManagers",
  MAIN_VIEW = "mainView",
  OBJECTS = "objects",
}

export enum NavNamesCustomer {
  DASHBOARD = "dashboard",
  ACCOUNTS = "accounts",
  OBJECTS = "objects",
  SERVICES = "services",
  NOTIFICATIONS = "notifications",
}

export enum RouterNames {
  TECHNICIAN = "technician",
  TGA_MANAGER = "tgaManager",
  CUSTOMER_ADMIN = "customerAdmin",
  CUSTOMER_USER = "customerUser",
  FACILITY_MANAGER = "facilityManager",
  COMPANY = "company",
  TRADES = "trades",
  SKILLS = "skills",
  TRADE = "trade",
  WORK_TYPE = "workType",
  TASK_LIST = "taskList",
}

export enum BreadcrumbTitles {
  CREATE_TECHNICIAN = "Create technician",
  NEW_TGA_MANAGER = "New TGA Manager",
  TGA_COMPANY = "TGA GmbH",
  NEW_USER = "New user account",
  CREATE_USER_PREFIX = "Create ",
  ADD_EMPLOYEE = "Add employee",
  CREATE_ACCOUNT = "Create account",
  CREATE_COMPANY = "Create Company",
}

export enum DictType {
  CLIENTS,
  CONSTR_PLACES,
  LOCALIZATIONS,
  DESTINY,
  DESTINY_GROUP,
  MATERIAL,
  CONCRETE_CLASS_GROUPS,
  CONCRETE_CLASS_SUBGROUP,
  CONCRETE_CLASS,
}

export enum SortDir {
  ASC,
  DESC,
}

export const INITIAL_SORT_FIELD = "id";
export const LABORANT_ROUTE_PREFIX = "/laborant";

export enum ExamType {
  CONCRETE,
  GEO,
  CERT,
}

export enum UserRightSymbols {
  VISIT_START = "VISIT_START",
}

export enum TakeExamPlaces {
  BATCHING_PLANT = "W - węzeł betoniarski", // Węzeł betoniarski
  CONSTRUCTION_SITE = "B - budowa", // Budowa
}

export enum ConsistencyNorms {
  S = "12350-2",
  F = "12350-5",
  SF = "12350-8",
}

export enum MakeSampleNorms {
  C = "12350-2",
  ALL = "PN-88",
}

export enum GroupClassSymbols {
  COMPRESSIVE_STREGTH = "C",
  COMPRESSIVE_STREGTH_LIGHT = "LC",
  CONSISTENCY = "S",
  CONSISTENCY_CONE = "SF",
  CONSISTENCY_TABLE = "F(rozpływ)",
  CHLORIDE = "Cl",
  EXPOSURE = "X",
  VISCOSITY = "VF",
  FROZEN = "F",
  WATER = "W",
}

export enum DestinyGroupCodes {
  COMPRESSIVE_STREGTH = "C",
  FROZEN = "F",
  WATER = "W",
}

export enum SlumpTestType {
  OK = "WŁAŚCIWY",
  NOK = "ŚCIĘTY",
}

export enum SignatureType {
  VISIT,
  PROTOCOL,
}

export enum ModuleType {
  LAB = 1,
  CLIENT = 2,
  ADMIN = 3, //to pewnie trzeba bedzie zmienic
}

export const statuses = [
  {
    color: "#21badc",
    title: "Done",
  },
  {
    color: "#21badc",
    title: "Confirmed",
  },
  {
    color: "#d8394c",
    title: "Planned",
  },
  {
    color: "#21badc",
    title: "Individual availability",
  },
  {
    color: "#21badc",
    title: "Vacation",
  },
  {
    color: "#21badc",
    title: "Overtime",
  },
  {
    color: "#21badc",
    title: "Sick",
  },
];

export enum TechnicianEventType {
  SICK = "sick",
  VACATION = "vacation",
  INDIVIDUAL_AVAILABILITY = "individual",
  OVERTIME = "overtime",
}

console.log("--- CONSTS FILE LODING END ---");
