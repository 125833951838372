
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TitleFormLine from "@/components/spec/TitleFormLine.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as helpers from "@/helpers";
import { User } from "@/types";
import Calendar from "@/components/spec/Calendar.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import ModalAddTechnicianEvent from "@/components/spec/ModalAddTechnicianEvent.vue";

@Component({
  components: {
    TitleFormLine,
    TopPanel,
    Calendar,
    ModalAddTechnicianEvent,
  },
})
export default class TechnicianCalendar extends Vue {
  @Ref() readonly modalAddEvent: ModalAddTechnicianEvent;
  user: User = new User();
  userId = Number(this.$route.params.user_id);

  addEvent() {
    this.modalAddEvent.showModal();
  }

  get eventsDays() {
    const eDays = [];
    /*
    TO-DO funkcja do dokonczenia
    this.getEvents.forEach((e) => {
      console.log(e.date, e.date.getFullYear());
      if (
        !eDays.find(
          (d) =>
            d.date.getFullYear() === e.date.getFullYear() &&
            d.date.getMonth() === e.date.getMonth() &&
            d.date.getDate() === e.date.getDate()
        )
      ) {
        eDays.push(e.date);
      }
    });*/
    return eDays;
  }

  get eventsSorted() {
    const events = this.getEvents.sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    );
    return events;
  }

  get getEvents() {
    return [
      {
        color: "#000",
        date: new Date(2023, 3, 15),
        type: 1,
        time_start: "15:30",
        time_end: "17:00",
        order_no: "S21028/2021",
        location: "Berlin",
        status: "Done",
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 15),
        type: 1,
        time_start: "15:30",
        time_end: "17:00",
        order_no: "S21028/2021",
        location: "Berlin",
        status: "Done",
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 15),
        type: 1,
        time_start: "17:30",
        time_end: "18:00",
        order_no: "S21028/2022",
        location: "Warsaw",
        status: "Confirmed",
      },
      {
        color: "#21badc",
        date: new Date(2023, 3, 25),
        type: 1,
        time_start: "15:30",
        time_end: "17:00",
        order_no: "S21028/2021",
        location: "Berlin",
        status: "Done",
      },
      {
        color: "#000",
        date: new Date(2023, 3, 16),
        type: 1,
        time_start: "15:30",
        time_end: "18:00",
        order_no: "S21024/2021",
        location: "Berlin",
        status: "Confirmed",
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 16),
        type: 1,
        time_start: "10:00",
        time_end: "12:00",
        order_no: "S21023/2021",
        location: "Berlin",
        status: "Done",
      },
      {
        color: "#21badc",
        date: new Date(2023, 3, 16),
        type: 1,
        time_start: "12:30",
        time_end: "14:00",
        order_no: "S21021/2021",
        location: "Helsinki",
        status: "Planned",
      },
    ];
  }

  get statuses() {
    return consts.statuses;
  }

  services = [
    {
      id: "1",
      Location: "Berlin",
      Date: "17.02.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
    {
      id: "2",
      Location: "Berlin",
      Date: "22.04.2021",
      Status: "Finished",
      DotColor: "green",
      BgColor: "gray",
    },
    {
      id: "3",
      Location: "Berlin",
      Date: "12.05.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
  ];

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  routingHandle() {
    this.$router.push({
      path: `/mainView/${consts.NavNames.TECHNICIAN}/${this.userId}`,
    });
  }

  async created() {
    const response = await API.getTechnician(this.userId);
    if (response.data.success) {
      this.user = response.data.user;
    } else {
      if (response.data.error_code === consts.ErrorCodes.OBJECT_NOT_FOUND) {
        console.log("User not found");
        helpers.error("There is no user with such id");
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/dashboard/",
      items: [
        {
          title: "Technicians",
          name: "technicians",
        },
        {
          title: this.user.first_name + " " + this.user.last_name,
          name: "technician/" + this.userId,
        },
        {
          title: "Calendar",
          name: "",
        },
      ],
    });
  }
}
