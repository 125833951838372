
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import ModalTask from "@/components/spec/ModalTask.vue";
import * as consts from "@/consts";
import moment from "moment";

@Component({
  components: {
    ModalTask,
  },
})
export default class TaskeCard extends Vue {
  @Ref() readonly modalTask: ModalTask;
  @Prop() task;
  @Prop() trade;

  get durationInMinutes() {
    return moment.duration(this.task.duration_time).asMinutes();
  }

  showModalTask() {
    this.modalTask.showModal(this.task);
  }

  modalTaskCancel() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
  }
}
