
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import moment from "moment";
import { TaskList } from "@/types";
import { TaskTypeStr } from "../../consts";

@Component({})
export default class ModalWorkType extends Vue {
  @Prop({ type: Number, required: true }) workTypeId;
  show = false;
  btnSave = 0;
  isNew = false;
  taskList: TaskList = new TaskList();
  taskType = [];
  taskTypesList = [
    consts.TaskTypeStr.MAINTENANCE,
    consts.TaskTypeStr.REPAIR,
    consts.TaskTypeStr.INSTALLATION,
    consts.TaskTypeStr.CUSTOM,
  ];
  taskTypes = [
    {
      id: 1,
      name: this.taskTypesList[0],
    },
    {
      id: 2,
      name: this.taskTypesList[1],
    },
    {
      id: 3,
      name: this.taskTypesList[2],
    },
    {
      id: 4,
      name: this.taskTypesList[3],
    },
  ];

  customTaskType = false;
  customTaskTypeName = "";

  showModal(taskList: TaskList = null) {
    if (taskList === null) {
      this.isNew = true;
      this.taskList = new TaskList();
    } else {
      this.taskList = taskList;
      if (
        this.taskTypesList.slice(0, 3).includes(taskList.name as TaskTypeStr)
      ) {
        const index = this.taskTypesList.indexOf(taskList.name as TaskTypeStr);
        this.selectTaskType(this.taskTypes[index]);
      } else {
        this.selectTaskType(this.taskTypes[this.taskTypes.length - 1]);
        this.customTaskTypeName = taskList.name;
      }
    }
    this.show = true;
  }

  selectTaskType(e) {
    this.taskType = e;
    this.customTaskType = e.name === consts.TaskTypeStr.CUSTOM;
  }

  async saveModal() {
    this.taskList.name =
      this.taskType["name"] === consts.TaskTypeStr.CUSTOM
        ? this.customTaskTypeName
        : this.taskType["name"];
    if (this.isNew === true) {
      this.taskList.work_type = this.workTypeId;
    } else {
      {
        this.taskList.manual_total_time =
          this.taskList.manual_total_time === null
            ? ""
            : moment
                .duration(this.taskList.manual_total_time)
                .asMinutes()
                .toString();
      }
    }
    const response = await API.saveTaskList(this.taskList);
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.TASK_LIST_EXISTS
    ) {
      console.log("Task list of this type already exists for this service");
      helpers.error("Task list of this type already exists for this service");
      this.btnSave++;
      return;
    } else if (response.data.success === false) {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
