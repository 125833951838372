
import { Vue, Component, Prop } from "vue-property-decorator";
import { UserTypeStr } from "@/consts";

@Component
export default class ProfileDetailFormLine extends Vue {
  @Prop({ default: "label" }) mainLabel!: string;
  @Prop({ default: "none" }) value!: string | number;
  @Prop({ default: "Edit" }) buttonLabel!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) dropdown!: boolean;
  @Prop({ default: true }) editable: boolean;

  currentValue: string | number | any = {};
  edit = false;

  get currentButtonLabel() {
    return this.edit ? "Confirm" : this.buttonLabel;
  }

  get userTypes() {
    return [
      UserTypeStr.CUSTOMER_USER,
      UserTypeStr.CUSTOMER_ADMIN,
      UserTypeStr.FACILITY_MANAGER,
    ];
  }

  selectUserType(e: string) {
    this.currentValue = e;
    console.log("selected", this.currentValue);
  }

  confirm() {
    this.edit = !this.edit;
    this.$emit("update", this.currentValue, this.value);
    this.value = this.currentValue;
  }

  async created() {
    this.currentValue = this.value;
  }
}
