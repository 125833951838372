
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component({
  components: {},
})
export default class TradeCard extends Vue {
  @Prop() trade;
  workTypes = [];

  get moreWorkTypesCount() {
    return this.workTypes.length - 3;
  }

  goToTrade() {
    this.$router.push({
      path: consts.RouterNames.TRADE,
      name: consts.RouterNames.TRADE,
      params: {
        trade_id: this.trade.id.toString(),
      },
    });
  }

  async created() {
    const response = await API.getWorkTypes();
    if (response.data.success) {
      this.workTypes = response.data.work_types.filter((wt) =>
        [this.trade.id, null].includes(wt.trade)
      );
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }
}
