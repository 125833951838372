
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import accountCard from "@/components/spec/AccountCard.vue";
import AuthModule from "@/store/modules/auth";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import {
  UserType,
  UserTypeStr,
  NavNames,
  SERVER_COMMUNICATION_ERROR,
} from "@/consts";
import { AccountOnCard, User } from "@/types";

@Component({
  components: {
    accountCard,
    TopPanel,
  },
})
export default class Accounts extends Vue {
  accounts: AccountOnCard[] = [];
  user: User = new User();
  filteredAccountsArray = [];
  filterInput = "";
  subtitleByCriteria = "Users";
  numberOfAccountsByCriteria = 0;
  roleFilters = this.isLoggedIn.Customer
    ? {
        [NavNames.CUSTOMER_ADMINS]: false,
        [NavNames.CUSTOMER_USERS]: false,
        [NavNames.FACILITY_MANAGERS]: false,
      }
    : {
        [NavNames.TGA_ADMIN]: false,
        [NavNames.TGA_MANAGERS]: false,
        [NavNames.CUSTOMER_ADMINS]: false,
        [NavNames.CUSTOMER_USERS]: false,
        [NavNames.FACILITY_MANAGERS]: false,
      };
  roleFiltersButtons = this.isLoggedIn.Customer
    ? [
        { filter: NavNames.CUSTOMER_ADMINS, role: UserTypeStr.CUSTOMER_ADMIN },
        {
          filter: NavNames.FACILITY_MANAGERS,
          role: UserTypeStr.FACILITY_MANAGER,
        },
        { filter: NavNames.CUSTOMER_USERS, role: UserTypeStr.CUSTOMER_USER },
      ]
    : [
        { filter: NavNames.TGA_ADMIN, role: UserTypeStr.TGA_ADMIN },
        { filter: NavNames.TGA_MANAGERS, role: UserTypeStr.TGA_MANAGER },
        { filter: NavNames.CUSTOMER_ADMINS, role: UserTypeStr.CUSTOMER_ADMIN },
        {
          filter: NavNames.FACILITY_MANAGERS,
          role: UserTypeStr.FACILITY_MANAGER,
        },
        { filter: NavNames.CUSTOMER_USERS, role: UserTypeStr.CUSTOMER_USER },
      ];

  serviceArea = [];
  serviceAreaId = null;
  serviceAreas = [];

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    this.changeStatus();
  }

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  get roleFiltersButtonsTrue() {
    return this.roleFiltersButtons.filter(
      (b) => this.roleFilters[b.filter] === true
    );
  }

  deleteOneFilter(filter) {
    this.roleFilters[filter] = false;
    this.filterAccounts();
  }

  clearFilters() {
    Object.entries(this.roleFilters).forEach(([key, val]) => {
      this.roleFilters[key] = true;
    });
    this.subtitleByCriteria = "Users";
    this.filterInput = "";
    this.serviceArea = [];
    this.serviceAreaId = null;
    this.filterAccounts();
  }

  async filterAccounts() {
    this.updateSubtitle();
    let filteredAccountsArray = this.accounts.filter((account) => {
      if (
        this.roleFilters[NavNames.TGA_MANAGERS] === true &&
        account.user_data.user_type == UserType.TGA_MANAGER
      )
        return true;
      if (
        this.roleFilters[NavNames.TGA_ADMIN] === true &&
        account.user_data.user_type == UserType.TGA_ADMIN
      )
        return true;
      if (
        this.roleFilters[NavNames.CUSTOMER_USERS] === true &&
        account.user_data.user_type == UserType.CUSTOMER_USER
      )
        return true;
      if (
        this.roleFilters[NavNames.FACILITY_MANAGERS] === true &&
        account.user_data.user_type == UserType.FACILITY_MANAGER
      )
        return true;
      if (
        this.roleFilters[NavNames.CUSTOMER_ADMINS] === true &&
        account.user_data.user_type == UserType.CUSTOMER_ADMIN
      )
        return true;
    });
    if (this.filterInput !== "" || this.serviceAreaId !== null) {
      if (this.filterInput !== "") {
        filteredAccountsArray = filteredAccountsArray.filter((account) => {
          if (
            account.username
              .toLowerCase()
              .includes(this.filterInput.toLowerCase()) ||
            account.name
              .toLowerCase()
              .includes(this.filterInput.toLowerCase()) ||
            account.company.name
              .toLowerCase()
              .includes(this.filterInput.toLowerCase()) ||
            account.company.nip.includes(this.filterInput)
          )
            return true;
        });
        this.numberOfAccountsByCriteria = filteredAccountsArray.length;
        this.filteredAccountsArray = filteredAccountsArray;
      }
      if (this.serviceAreaId !== null) {
        filteredAccountsArray = filteredAccountsArray.filter((account) => {
          if (
            account.company.correspond_address.service_area ===
            this.serviceAreaId
          )
            return true;
        });
        this.numberOfAccountsByCriteria = filteredAccountsArray.length;
        this.filteredAccountsArray = filteredAccountsArray;
      }
    } else {
      this.numberOfAccountsByCriteria = filteredAccountsArray.length;
      this.filteredAccountsArray = filteredAccountsArray;
    }
    this.$forceUpdate();
  }

  updateSubtitle() {
    let oneKey = "";
    let checked = 0;

    Object.entries(this.roleFilters).forEach(([key, val]) => {
      if (val == true) {
        checked++;
        oneKey = key;
      }
    });

    if (checked == 1) {
      if (oneKey == NavNames.TGA_MANAGERS)
        this.subtitleByCriteria = "TGA Managers";
      if (oneKey == NavNames.CUSTOMER_USERS)
        this.subtitleByCriteria = "Customer Users";
      if (oneKey == NavNames.CUSTOMER_ADMINS)
        this.subtitleByCriteria = "Customer Admins";
      if (oneKey == NavNames.FACILITY_MANAGERS)
        this.subtitleByCriteria = "Facility Managers";
    } else this.subtitleByCriteria = "Users";
  }

  async setStatus() {
    if (this.$route.query.userType === undefined) {
      Object.keys(this.roleFilters).forEach(
        (key) => (this.roleFilters[key] = true)
      );
    } else if (Array.isArray(this.$route.query.userType) === true) {
      this.roleFilters[`${this.$route.query.userType[0]}`] = true;
      this.roleFilters[`${this.$route.query.userType[1]}`] = true;
      if (this.$route.query.userType.length > 2) {
        this.roleFilters[`${this.$route.query.userType[2]}`] = true;
      }
    } else {
      this.roleFilters[`${this.$route.query.userType}`] = true;
    }
    if (Object.keys(this.$route.params).includes("company_id")) {
      const companyId = Number(this.$route.params.company_id);
      const response = await API.getCompany(companyId);
      if (response.data.success) {
        this.filterInput = response.data.company.name;
      } else {
        helpers.error(SERVER_COMMUNICATION_ERROR);
      }
    }
    this.filterAccounts();
  }

  changeStatus() {
    Object.keys(this.roleFilters).forEach(
      (key) => (this.roleFilters[key] = false)
    );
    this.setStatus();
  }

  selectServiceArea(e: any) {
    this.serviceArea = e;
    this.serviceAreaId = this.serviceArea["id"];
    this.filterAccounts();
  }

  async created() {
    this.user = AuthModule.currentUser;
    let response = await API.getAccounts();
    if (response.data.success) {
      this.accounts = response.data.accounts;
      if (this.isLoggedIn.Customer) {
        const response = await API.getCustomerManagerUser(this.user.id);
        if (response.data.success) {
          const companyId = response.data.company.id;
          this.accounts = this.accounts.filter(
            (a) => a.company.id === companyId
          );
        } else {
          helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        }
      }
      const neededAccounts: AccountOnCard[] = this.accounts.filter(
        (account) => {
          if (
            [
              UserType.CUSTOMER_ADMIN,
              UserType.CUSTOMER_USER,
              UserType.TGA_ADMIN,
              UserType.TGA_MANAGER,
              UserType.FACILITY_MANAGER,
            ].includes(account.user_data.user_type)
          )
            return true;
        }
      );
      this.accounts = neededAccounts;
      this.filteredAccountsArray = neededAccounts;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.setStatus();
    response = await API.getServiceAreas();
    if (response.data.success) {
      this.serviceAreas = response.data.service_areas.map((area) => ({
        ...area,
        code_name: area.code + " - " + area.name,
      }));
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.ACCOUNTS.charAt(0).toUpperCase() +
            consts.NavNames.ACCOUNTS.slice(1),
          name: "",
        },
      ],
    });
  }
  routingHandle(path: string) {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
      name: path,
    });
  }
}
