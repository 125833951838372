
import { Component, Prop, Vue } from "vue-property-decorator";
import ServiceAreas from "@/components/spec/ServiceAreas.vue";
import * as consts from "@/consts";

@Component({
  components: {
    ServiceAreas,
  },
})
export default class ModalServiceAreas extends Vue {
  @Prop({ required: false, default: [] }) selectedServiceAreas;
  show = false;
  btnSave = 0;

  updateServiceAreas(e) {
    this.selectedServiceAreas = e;
    console.log("update service areas", this.selectedServiceAreas);
  }

  showModal() {
    this.show = true;
  }

  async saveModal() {
    this.$emit(consts.ModalEvents.OK_EVENT, this.selectedServiceAreas);
    this.show = false;
  }
  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
