
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalTrade from "@/components/spec/ModalTrade.vue";
import TradeCard from "@/components/spec/TradeCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component({
  components: {
    ModalTrade,
    TradeCard,
    TopPanel,
  },
})
export default class Trades extends Vue {
  @Ref() readonly modalTrade: ModalTrade;
  trades = [];

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  routingHandle(path: string) {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
    });
  }

  async showModalTrade() {
    await this.modalTrade.showModal();
  }

  async created() {
    const response = await API.getTrades();
    if (response.data.success) {
      this.trades = response.data.trades;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TEMPLATES.charAt(0).toUpperCase() +
            consts.NavNames.TEMPLATES.slice(1),
          name: consts.NavNames.TEMPLATES,
        },
        {
          title:
            consts.RouterNames.TRADES.charAt(0).toUpperCase() +
            consts.RouterNames.TRADES.slice(1),
          name: "",
        },
      ],
    });
  }
}
