
import { Component, Prop, Vue } from "vue-property-decorator";
import * as consts from "@/consts";

@Component
export default class Avatar extends Vue {
  @Prop({ type: Object, default: null }) bus!: any;
  @Prop({ default: false }) withUpperRightButton!: boolean;
  @Prop({ default: false }) edit!: boolean; // czy edycja dostepna

  // showEmptyProfileImg - czy pokazywac defaultowe zdjecie,
  // jezeli nie to pusty okrag z tekstem Upload image
  @Prop({ default: false }) showEmptyProfileImg!: boolean;

  @Prop() width!: number;
  @Prop() height!: number;
  @Prop({ type: Function, default: null }) loadMethod: any;
  @Prop({ type: Function, default: null }) uploadMethod: any;
  @Prop({ type: Function, default: null }) removeMethod: any;

  events: [consts.Events.REMOVE_EVENT];

  imageSourceDefault = "@/assets/default-profile-round.png";
  imageSource = null;

  launchFilePicker() {
    (this.$refs.fileInput as any).click();
  }

  createImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageSource = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  async removeImage() {
    console.log("RemoveAvatar from Avatar 1");
    if (this.removeMethod !== null) {
      console.log("RemoveAvatar from Avatar 2");
      await this.removeMethod();
    }
    this.imageSource = null;
  }

  async onFileChange(e) {
    const imageFile = e.target.files[0];
    this.createImage(imageFile); // zapisujemy zdjecie aby go wczytac na ekran
    if (this.uploadMethod !== null) {
      await this.uploadMethod(imageFile);
    }
  }

  async mounted() {
    if (this.bus !== null) {
      this.bus.$on(consts.Events.REMOVE_EVENT, this.removeImage);
    }
    if (this.loadMethod !== null) {
      console.log("Load Avatar image");
      this.imageSource = await this.loadMethod();
      //console.log("imageSource", this.imageSource);
      this.$forceUpdate();
    }
  }
}
