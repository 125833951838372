
import { Component, Prop, Vue } from "vue-property-decorator";

class CalendarEvent {
  color: "#000";
  date: Date;
}

@Component
export default class Calendar extends Vue {
  @Prop({ type: Object, default: null }) bus!: any;
  @Prop({ type: Date, default: () => new Date() }) currentDate: Date;
  @Prop({ type: Array, default: () => [] }) events: CalendarEvent[];
  @Prop({ type: Number, default: 500 }) maxWidth: number;

  current: Date = new Date();
  isReady = false;

  dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  nextMonth() {
    this.current.setMonth(this.month + 1);
    this.current = new Date(this.current);
  }
  prevMonth() {
    this.current.setMonth(this.month - 1);
    this.current = new Date(this.current);
  }

  get dayColor() {
    return (dayName) => {
      return dayName === this.dayNames[5] || dayName === this.dayNames[6]
        ? "color: #a5b3bf !important"
        : "color: #007cc5 !important";
    };
  }

  get dayColorInt() {
    return (day) => {
      const dt = new Date(this.year, this.month, day);
      return dt.getDay() === 6 || dt.getDay() === 0
        ? "background: rgb(238,240,242) !important; border: 1px solid white;"
        : "";
    };
  }

  get getCurrent() {
    return this.monthNames[this.month] + " " + this.current.getFullYear();
  }

  // zwraca ilosc dni z poprzedniego mc ktore wystepuja w pierwszym tygodniu mc
  get getFirstWeekDaysCountFromPrevMonth() {
    const firstDayOfMonth = new Date(this.year, this.month, 1);
    return firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1;
  }

  get getLastWeekDaysCountFromNextMonth() {
    const day = new Date(this.year, this.month, this.monthDays);
    return day.getDay() === 0 ? 0 : 7 - day.getDay();
  }

  get year() {
    return this.current.getFullYear();
  }
  get month() {
    return this.current.getMonth();
  }

  get monthDays() {
    return new Date(this.year, this.month + 1, 0).getDate();
  }

  get prevMonthDays() {
    if (this.month === 0) {
      return 31;
    } else {
      return new Date(this.year, this.month, 0).getDate();
    }
  }

  // konwertuje date eventu na kolumne
  get getEventColumn() {
    return (event) => {
      return event.date.getDay();
    };
  }

  get dayEvents() {
    return (day) => {
      return this.events.filter(
        (e) => e.date.getDate() === day && e.date.getMonth() === this.month
      );
    };
  }

  mounted() {
    this.current = this.currentDate;
    this.isReady = true;
  }
}
