var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modalRef",attrs:{"id":"modal-skills-work-type","ok-only":"","size":"xl","centered":"","hide-footer":"","hide-header":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"d-block text-center m-0 p-0"},[_c('div',{staticClass:"row panel-header mt-2 px-4"},[_c('div',{staticClass:"col-8 panel-header-title"},[_vm._v("Add Skills")]),_c('div',{staticClass:"filter-skills col-4"},[_c('div',{staticClass:"row mt-2 ml-2",staticStyle:{"position":"relative"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{staticClass:"search-icon",attrs:{"src":require("@/assets/search.svg")},on:{"click":_vm.filterSkills}})]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"filter-input","placeholder":"Search for skills..."},on:{"change":_vm.filterSkills,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.filterSkills.apply(null, arguments)}},model:{value:(_vm.filterInput),callback:function ($$v) {_vm.filterInput=$$v},expression:"filterInput"}})],1)],1)])]),_c('div',{staticClass:"horizontal-line-filter mt-3"}),_c('div',{staticClass:"row m-0 p-0"},[_c('div',{staticClass:"col-12 mt-3 m-0 p-0"},[_c('div',{staticClass:"row panel-header p-0 px-4"},[_c('div',{staticClass:"col-8 text-left ml-0"},[_c('span',{staticClass:"panel-header-title mr-2 p-0"},[_c('span',{staticClass:"mr-2",style:('color:' + _vm.trade.color)},[_vm._v(_vm._s(_vm.trade.code))]),_vm._v(_vm._s(_vm.trade.name))]),_vm._v(" "+_vm._s("(" + _vm.skills.length + ")")+" ")]),_c('div',{staticClass:"col-4 text-right ml-0"},[_vm._v(" Selected: "+_vm._s(_vm.skillsCurrentlySelected.length)+" ")])]),_c('div',{staticClass:"skills"},_vm._l((_vm.filteredSkills),function(skill,index){return _c('div',{key:index,class:skill.selected
                ? 'badge skill-tag-selected m-3'
                : 'badge skill-tag m-3',style:(skill.selected
                ? 'border-color:' +
                  _vm.trade.color +
                  ';background-color:' +
                  _vm.trade.color
                : 'border-color:' +
                  _vm.trade.color +
                  '0F; background:' +
                  _vm.trade.color +
                  '0F'),on:{"click":function($event){return _vm.toggleSelectSkill(skill)}}},[_c('span',{staticClass:"mr-2",style:(skill.selected ? 'color: white' : 'color:' + _vm.trade.color)},[_vm._v(_vm._s(skill.code))]),_c('span',{style:(skill.selected ? 'color: white' : '')},[_vm._v(_vm._s(skill.name))])])}),0),_c('div',{staticClass:"horizontal-line-filter my-4"}),_c('div',{staticClass:"row my-3 footer"},[_c('div',{staticClass:"col-9"}),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"white-button inline mr-4",on:{"click":_vm.hideModal}},[_vm._v(" Cancel ")]),_c('div',{key:_vm.btnSave,staticClass:"create-button inline ml-4",on:{"~click":function($event){return _vm.saveModal.apply(null, arguments)}}},[_vm._v(" Add to List ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }