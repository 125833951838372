
import { Component, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import SuccessfullyCreated from "@/components/spec/SuccessfullyCreated.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Company, Address } from "@/types";

@Component({
  components: {
    SuccessfullyCreated,
    TopPanel,
  },
})
export default class CreateCompany extends Vue {
  saved = false;
  btnSave = 0;
  companyId = null;
  company: Company = new Company();
  address: Address = new Address();
  federalState = [];
  federalStateId = null;
  federalStates = [];
  serviceArea = [];
  serviceAreaId = null;
  serviceAreas = [];

  get isMasterDataFilled() {
    return (
      this.company.name !== "" &&
      this.company.nip !== "" &&
      this.company.email !== "" &&
      this.company.phone !== ""
    );
  }

  get isAddressFilled() {
    return (
      this.address.street !== "" &&
      this.address.house_no !== "" &&
      this.address.city !== "" &&
      this.address.postal_code !== "" &&
      this.federalStateId !== null &&
      this.serviceAreaId !== null
    );
  }

  get isSaveEnabled() {
    return this.isAddressFilled && this.isMasterDataFilled;
  }

  async saveCompany() {
    this.address["federal_state"] = this.federalStateId;
    this.address["service_area"] = this.serviceAreaId;
    this.company.correspond_address = this.address;
    console.log("company to save", this.company);
    const response = await API.saveCompany(this.company);

    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.COMPANY_NIP_EXISTS
    ) {
      console.log("Company number already exists");
      helpers.error("Company number already exists, please use another");
      this.btnSave++;
      return;
    }

    if (response.data.success) {
      console.log("success data", response.data);
      this.companyId = response.data.company_id;
      this.saved = true;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  selectFederalState(e: any) {
    this.federalState = e;
    this.federalStateId = this.federalState["id"];
    console.log("selected", this.federalState);
  }

  selectServiceArea(e: any) {
    this.serviceArea = e;
    this.serviceAreaId = this.serviceArea["id"];
    console.log("selected", this.serviceArea);
  }

  async created() {
    API.getCompanyId().then((res) => {
      this.companyId = res.data.id;
    });
    let response = await API.getFederalStates();
    if (response.data.success) {
      this.federalStates = response.data.federal_states;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    response = await API.getServiceAreas();
    if (response.data.success) {
      this.serviceAreas = response.data.service_areas.map((area) => ({
        ...area,
        code_name: area.code + " - " + area.name,
      }));
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.CUSTOMER_COMPANIES.charAt(0).toUpperCase() +
            consts.NavNames.CUSTOMER_COMPANIES.slice(1),
          name: consts.NavNames.CUSTOMER_COMPANIES,
        },
        {
          title: consts.BreadcrumbTitles.CREATE_COMPANY,
          name: "",
        },
      ],
    });
  }
}
