var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modalRef",attrs:{"id":"modal-skills-technician","ok-only":"","size":"xl","centered":"","hide-footer":"","hide-header":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"d-block text-center"},[_c('div',{staticClass:"row panel-header mt-2"},[_c('div',{staticClass:"col-8 panel-header-title"},[_vm._v("Add Technician Skills")]),_c('div',{staticClass:"filter-skills col-4"},[_c('div',{staticClass:"row mt-2 mr-3",staticStyle:{"position":"relative"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{staticClass:"search-icon",attrs:{"src":require("@/assets/search.svg")},on:{"click":_vm.searchForSkills}})]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"filter-input","placeholder":"Search for skills..."},on:{"change":_vm.searchForSkills,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchForSkills.apply(null, arguments)}},model:{value:(_vm.filterInput),callback:function ($$v) {_vm.filterInput=$$v},expression:"filterInput"}})],1)],1)])]),_c('div',{staticClass:"horizontal-line-filter mt-3"}),_c('div',{staticClass:"row trades-skills m-0 p-0"},[_c('div',{staticClass:"col-2 trade-menu m-0 p-0"},_vm._l((_vm.trades),function(trade,index){return _c('div',{key:index,staticClass:"py-3 pl-5 data-text-dark-gray p-link d-flex justify-content-left",style:(_vm.selectedTrade.id === trade.id ? 'background-color: #A5B3BF29' : ''),on:{"click":function($event){return _vm.selectTrade(trade)}}},[(trade.id !== 0)?_c('div',[(
                _vm.selectedTradeSkills.filter((sts) => sts.trade.id === trade.id)
                  .length === 0
              )?_c('div',{staticClass:"inline ml-1 mr-3",style:('color:' + trade.color)},[_c('font-awesome-icon',{attrs:{"icon":"circle","size":"xs"}})],1):_c('div',{key:trade.id,staticClass:"badge skill-tag-selected py-1 px-2 mr-2",style:('border-color:' +
                trade.color +
                ';background-color:' +
                trade.color)},[_vm._v(" "+_vm._s(_vm.selectedTradeSkills.filter((sts) => sts.trade.id === trade.id) .length)+" ")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(trade.name,15)))])])}),0),_c('div',{staticClass:"col-10 m-0 p-0"},[_c('div',{staticClass:"row panel-header m-0 p-0 px-2"},[_c('div',{staticClass:"col-8 text-left ml-0"},[_c('span',{staticClass:"panel-header-title mr-2 p-0"},[_c('span',{staticClass:"mr-2",style:('color:' + _vm.selectedTrade.color)},[_vm._v(_vm._s(_vm.selectedTrade.code))]),_vm._v(_vm._s(_vm.selectedTrade.name))]),_vm._v(" "+_vm._s("(" + _vm.tradeSkillsToShow.length + ")")+" ")]),_c('div',{staticClass:"col-4 text-right mr-0"},[_vm._v(" Selected: "+_vm._s(_vm.selectedTrade.id === 0 ? _vm.selectedTradeSkills.length : _vm.selectedTradeSkills.filter( (sts) => sts.trade.id === _vm.selectedTrade.id ).length)+" ")])]),_c('div',{staticClass:"skills"},_vm._l((_vm.tradeSkillsToShow),function(tradeSkill,index){return _c('div',{key:index,class:tradeSkill.selected
                ? 'badge skill-tag-selected m-3'
                : 'badge skill-tag m-3',style:(tradeSkill.selected
                ? 'border-color:' +
                  tradeSkill.trade.color +
                  ';background-color:' +
                  tradeSkill.trade.color
                : 'border-color:' +
                  tradeSkill.trade.color +
                  '0F; background:' +
                  tradeSkill.trade.color +
                  '0F'),on:{"click":function($event){return _vm.toggleSelectTradeSkill(tradeSkill)}}},[_c('span',{staticClass:"mr-1",style:(tradeSkill.selected
                  ? 'color: white'
                  : 'color:' + tradeSkill.trade.color)},[_vm._v(_vm._s(tradeSkill.skill.code))]),_c('span',{staticClass:"mr-2",style:(tradeSkill.selected ? 'color: white' : '')},[_vm._v(_vm._s(tradeSkill.skill.name))])])}),0),_c('div',{staticClass:"horizontal-line-filter m-0 p-0"}),_c('div',{staticClass:"row my-3 footer"},[_c('div',{staticClass:"col-9"}),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"white-button inline mr-4",on:{"click":_vm.hideModal}},[_vm._v(" Cancel ")]),_c('div',{key:_vm.btnSave,staticClass:"create-button inline ml-4",on:{"~click":function($event){return _vm.saveModal.apply(null, arguments)}}},[_vm._v(" Save ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }