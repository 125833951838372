
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ModalSkillsWorkType from "@/components/spec/ModalSkillsWorkType.vue";
import ModalTaskList from "@/components/spec/ModalTaskList.vue";
import ModalWorkType from "@/components/spec/ModalWorkType.vue";
import TaskListCard from "@/components/spec/TaskListCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Skill, Trade, WorkType, TaskList } from "@/types";

const SKILLS_T0_SHOW_COUNT = 13;
const DESCRIPTION_CHAR_LIMIT = 1350;

@Component({
  components: {
    ModalDelete,
    ModalSkillsWorkType,
    ModalTaskList,
    ModalWorkType,
    TaskListCard,
    TopPanel,
  },
})
export default class WorkTypeDetail extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  @Ref() readonly modalSkillsWorkType: ModalSkillsWorkType;
  @Ref() readonly modalTaskList: ModalTaskList;
  @Ref() readonly modalWorkType: ModalWorkType;
  tradeId = null;
  workTypeId = null;
  workType: WorkType = new WorkType();
  workTypeTemplateType = [
    consts.TemplateType.WORK_TYPE,
    consts.TemplateTypeStr.WORK_TYPE,
  ];
  trade: Trade = new Trade();
  descriptionSavedInDb = "";
  skills: Skill[] = [];
  taskLists: TaskList[] = [];
  btnSave = 0;
  skillsToShowCount = SKILLS_T0_SHOW_COUNT;
  descriptionCharLimit = DESCRIPTION_CHAR_LIMIT;

  get isDescriptionToSave() {
    return (
      this.descriptionSavedInDb !== this.workType["description"] &&
      this.workType["description"].length <= DESCRIPTION_CHAR_LIMIT
    );
  }

  get moreSkills() {
    return this.skills.length - SKILLS_T0_SHOW_COUNT;
  }

  async getWorkType() {
    const response = await API.getWorkType(this.workTypeId);
    if (response.data.success) {
      this.workType = response.data.work_type;
      this.descriptionSavedInDb = this.workType["description"];
      this.skills = this.workType["skills"];
      console.log("workType", this.workType);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async getTrade() {
    const response = await API.getTrade(this.tradeId);
    if (response.data.success) {
      this.trade = response.data.trade;
      console.log("trade", this.trade);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async getTaskLists() {
    const response = await API.getTaskLists(this.workTypeId);
    if (response.data.success) {
      this.taskLists = response.data.task_lists;
      console.log("task lists", this.taskLists);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  setBreadcrumbs() {
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TEMPLATES.charAt(0).toUpperCase() +
            consts.NavNames.TEMPLATES.slice(1),
          name: consts.NavNames.TEMPLATES,
        },
        {
          title:
            consts.RouterNames.TRADES.charAt(0).toUpperCase() +
            consts.RouterNames.TRADES.slice(1),
          name: consts.RouterNames.TRADES,
        },
        {
          title: this.trade.name,
          name: "templates/",
          params: {
            trade_id: this.trade.id.toString(),
          },
        },
        {
          title: this.workType["name"],
          name: "",
        },
      ],
    });
  }

  routingHandle() {
    this.$router.push({
      path: `/mainView/${consts.RouterNames.TRADE}`,
      name: consts.RouterNames.TRADE,
      params: {
        trade_id: this.tradeId.toString(),
      },
    });
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async showModalWorkType(workType) {
    await this.modalWorkType.showModal(workType);
  }

  async modalWorkTypeOk() {
    await this.getWorkType();
    this.setBreadcrumbs();
  }

  async modalWorkTypeCancel() {
    await this.getWorkType();
  }

  async showModalSkillsWorkType() {
    await this.modalSkillsWorkType.showModal(this.skills);
  }

  async modalAddSkillOk() {
    await this.getWorkType();
  }

  async showModalTaskList() {
    await this.modalTaskList.showModal();
  }

  async modalTaskListOk() {
    await this.getTaskLists();
  }

  async deleteWorkType() {
    const response = await API.deleteWorkType(this.workTypeId);
    if (response.data.success) {
      helpers.info("Service has been deleted");
      this.$router.push({
        path: consts.RouterNames.TRADE,
        name: consts.RouterNames.TRADE,
        params: {
          trade_id: this.trade.id.toString(),
        },
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async saveWorkType() {
    const response = await API.saveWorkType(this.workType);
    if (response.data.success) {
      this.descriptionSavedInDb = this.workType["description"];
      this.btnSave++;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async created() {
    this.workTypeId = Number(this.$route.params.work_type_id);
    this.tradeId = Number(this.$route.params.trade_id);
    await this.getWorkType();
    await this.getTrade();
    this.setBreadcrumbs();
    await this.getTaskLists();
  }
}
