var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"trade-card mr-3 mb-3"},[_c('div',{staticClass:"row m-0 ml-2 pt-4 title-row text-center"},[_c('div',{staticClass:"col-11"},[_c('h6',[_vm._v(" "+_vm._s(_vm.workType.code)+" – "),_c('span',{staticClass:"name-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToWorkType()}}},[_vm._v(_vm._s(_vm._f("truncate")(_vm.workType.name,27)))])])]),_c('div',{staticClass:"col-1 m-0 p-0"},[_c('img',{staticClass:"right-arrow align-right",attrs:{"src":require("@/assets/right-arrow.svg"),"alt":"right-arrow"},on:{"click":function($event){return _vm.goToWorkType()}}})])]),_c('div',{staticClass:"horizontal-line-card mt-0 mx-0"}),_c('div',{staticClass:"row m-0 ml-3 pt-4 trade-main"},[_c('div',{staticClass:"row ml-2 mr-3",staticStyle:{"height":"90px","width":"350px"}},[(_vm.workType.description)?_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.workType.description,215))+" ")]):_c('span',[_vm._v(" No requirements. ")])]),(_vm.skills.length === 0)?_c('div',{staticClass:"row mt-2-0 mb-0"},[_vm._v("None")]):_c('div',{staticClass:"row m-2 mb-0"},[_vm._l((_vm.skills.slice(0, _vm.skillsToShowCount)),function(skill,index){return _c('div',{key:index,staticClass:"skill-tag-on-card m-1",style:('border-color:' +
          _vm.trade.color +
          '0F; background:' +
          _vm.trade.color +
          '0F')},[_c('span',{staticClass:"mr-1",style:('color:' + _vm.trade.color)},[_vm._v(_vm._s(skill.code))]),_vm._v(" "+_vm._s(skill.name)+" ")])}),(_vm.skills.length > _vm.skillsToShowCount)?_c('div',{staticClass:"skill-no-tag-on-card m-1",style:('border-color:' +
          _vm.trade.color +
          '0F; background:' +
          _vm.trade.color +
          '0F')},[_vm._v(" "+_vm._s(" + " + _vm.moreSkills)+" ")]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }