
import { Component, Ref, Vue } from "vue-property-decorator";
import Avatar from "@/components/spec/Avatar.vue";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalSkillsTechnician from "@/components/spec/ModalSkillsTechnician.vue";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ModalServiceAreas from "@/components/spec/ModalServiceAreas.vue";
import ProfileDetailFormLine from "@/components/spec/ProfileDetailFormLine.vue";
import ServiceHistoryLine from "@/components/spec/ServiceHistoryLine.vue";
import TechnicianLocationForm from "@/components/spec/TechnicianLocationForm.vue";
import TitleFormLine from "@/components/spec/TitleFormLine.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import * as userHelper from "@/helpers/user";
import { Address, ServiceArea, Skill, User } from "@/types";
import CalendarWeek from "@/components/spec/CalendarWeek.vue";

const TAGS_T0_SHOW_COUNT = 1;

@Component({
  components: {
    Avatar,
    ModalSkillsTechnician,
    ModalDelete,
    ModalServiceAreas,
    ProfileDetailFormLine,
    ServiceHistoryLine,
    TechnicianLocationForm,
    TitleFormLine,
    TopPanel,
    CalendarWeek,
  },
})
export default class Technician extends Vue {
  @Ref() readonly modalSkillsTechnician: ModalSkillsTechnician;
  @Ref() readonly modalDelete: ModalDelete;
  @Ref() readonly modalServiceAreas: ModalServiceAreas;
  user: User = new User();
  address: Address = new Address();
  userId = Number(this.$route.params.user_id);
  skills: Skill[] = [];
  selectedTradeSkills = [];
  serviceAreas: ServiceArea[] = [];
  selectedServiceAreas = [];
  linkToCalendar = `/mainView/${consts.NavNames.TECHNICIAN_CALENDAR}/${this.userId}`;
  tagsToShowCount = TAGS_T0_SHOW_COUNT;

  get getEvents() {
    return [
      {
        color: "#000",
        date: new Date(2023, 3, 15),
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 15),
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 15),
      },
      {
        color: "#21badc",
        date: new Date(2023, 3, 25),
      },
      {
        color: "#000",
        date: new Date(2023, 3, 16),
      },
      {
        color: "#d8394c",
        date: new Date(2023, 3, 16),
      },
      {
        color: "#21badc",
        date: new Date(2023, 3, 17),
      },
    ];
  }

  get statuses() {
    return consts.statuses;
  }

  services = [
    {
      id: "1",
      Location: "Berlin",
      Date: "17.02.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
    {
      id: "2",
      Location: "Berlin",
      Date: "22.04.2021",
      Status: "Finished",
      DotColor: "green",
      BgColor: "gray",
    },
    {
      id: "3",
      Location: "Berlin",
      Date: "12.05.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
  ];

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  get additionalServiceAreasCount() {
    return this.selectedServiceAreas.length - TAGS_T0_SHOW_COUNT;
  }

  get additionalTradeSkills() {
    return this.selectedTradeSkills.length - TAGS_T0_SHOW_COUNT;
  }

  async uploadUserAvatar(imageFile) {
    await userHelper.uploadUserAvatar(imageFile, this.user);
  }
  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.userId);
  }
  async removeUserAvatar() {
    await userHelper.removeUserAvatar(this.userId);
  }

  async handleUpdate($emit, value) {
    // TO-DO obsluzyc zmiane hasla, obecnie disabled
    console.log("emit", $emit, "value", value);
    if ($emit === undefined) {
      console.log("new address", value);
      await API.saveAddress(value, this.address.id);
    } else {
      const key = helpers.getKeyByValue(this.user, value);
      this.user[key] = $emit;
      const response = await API.saveUserField(
        this.user[key],
        key,
        this.userId
      );
      if (response.data.success === false) {
        if (response.data.error_code === consts.ErrorCodes.USER_EMAIL_EXISTS) {
          console.log("User email already exists");
          helpers.error("User email already exists, please use another");
          return;
        } else {
          helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        }
      }
    }
  }

  async handleUpdateTitleFormLine(user) {
    console.log("emit user", user);
    for (const value of [user.first_name, user.last_name, user.username]) {
      const key = helpers.getKeyByValue(user, value);
      await API.saveUserField(user[key], key, this.userId);
    }
  }

  routingHandle() {
    this.$router.push({
      path: `/mainView/${consts.NavNames.TECHNICIANS}`,
    });
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteUser() {
    const response = await API.deleteUser(this.user.id);
    if (response.data.success) {
      helpers.info("User account has been deleted");
      this.$router.push({
        path: `/mainView/${consts.NavNames.TECHNICIANS}`,
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async showModalSkillsTechnician(selectedTradeSkills) {
    await this.modalSkillsTechnician.showModal(selectedTradeSkills);
  }

  async modalAddSkillOk(selectedTradeSkills) {
    this.selectedTradeSkills = selectedTradeSkills;
    const selectedSkills = [];
    this.selectedTradeSkills.forEach((sts) => {
      if (!selectedTradeSkills.includes(sts.skill.id)) {
        selectedSkills.push(sts.skill);
      }
    });
    const response = await API.saveUserSkills(this.userId, selectedSkills);
    if (response.data.success) {
      this.skills = response.data.skills;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  showModalServiceAreas() {
    this.modalServiceAreas.showModal();
  }

  async editServiceAreas(selectedServiceAreas) {
    this.selectedServiceAreas = selectedServiceAreas;
    const response = await API.saveUserServiceAreas(
      this.userId,
      selectedServiceAreas
    );
    if (response.data.success) {
      this.serviceAreas = response.data.service_areas;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async created() {
    let response = await API.getTechnician(this.userId);
    if (response.data.success) {
      this.user = response.data.user;
      this.user.mobile_phone = this.user.user_data.mobile_phone;
      console.log("user", this.user);
      this.address = this.user.address;
      this.serviceAreas = this.user.service_areas;
      this.selectedServiceAreas = this.serviceAreas;
      this.skills = this.user.skills;
      response = await API.getTradeSkills();
      if (response.data.success) {
        const tradeSkills = response.data.trade_skills.map((ts) => ({
          ...ts,
          selected: true,
        }));
        this.skills.forEach((s) => {
          for (const ts of tradeSkills) {
            if (ts.skill.id === s.id) {
              this.selectedTradeSkills.push(ts);
            }
          }
        });
        this.selectedTradeSkills = [
          ...new Map(
            this.selectedTradeSkills.map((ts) => [ts.skill.id, ts])
          ).values(),
        ];
        console.log("selected trade skills");
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    } else {
      if (response.data.error_code === consts.ErrorCodes.OBJECT_NOT_FOUND) {
        console.log("User not found");
        helpers.error("There is no user with such id");
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TECHNICIANS.charAt(0).toUpperCase() +
            consts.NavNames.TECHNICIANS.slice(1),
          name: consts.NavNames.TECHNICIANS,
        },
        {
          title: this.user.first_name + " " + this.user.last_name,
          name: "",
        },
      ],
    });
  }
}
