
import { Component, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import Avatar from "@/components/spec/Avatar.vue";

@Component({
  components: {
    Avatar,
  },
})
export default class ModalSkillsTechnician extends Vue {
  show = false;
  btnSave = 0;
  filterInput = "";
  skills = [];
  trades = [];
  tradeSkills = [];
  tradeSkillsToShow = [];
  selectedTrade = {};
  selectedTradeSkills = [];

  getTradeSkillsToShow() {
    if (this.selectedTradeSkills.length > 0) {
      for (const sts of this.selectedTradeSkills) {
        const isFound = this.tradeSkills.some((ts) => {
          if (ts.id === sts.id) {
            return true;
          }
          return false;
        });
        if (isFound === true) {
          this.tradeSkills = this.tradeSkills.map((ts) => {
            if (ts.id === sts.id) {
              return { ...ts, selected: true };
            }
            return ts;
          });
        }
      }
    }
    if (this.selectedTrade["id"] === 0) {
      this.tradeSkillsToShow = this.tradeSkills;
    } else {
      this.tradeSkillsToShow = this.tradeSkills.filter(
        (ts) => ts.trade.code === this.selectedTrade["code"]
      );
    }
  }

  filterSkillsByInput() {
    if (this.filterInput !== "") {
      let filteredSkills = this.tradeSkillsToShow;
      filteredSkills = filteredSkills.filter((ts) => {
        if (
          ts.skill.code
            .toLowerCase()
            .includes(this.filterInput.toLowerCase()) ||
          ts.skill.name.toLowerCase().includes(this.filterInput.toLowerCase())
        ) {
          return true;
        }
      });
      this.tradeSkillsToShow = filteredSkills;
    }
  }

  showSkills() {
    this.getTradeSkillsToShow();
    this.filterSkillsByInput();
  }

  selectTrade(trade) {
    this.selectedTrade = trade;
    this.showSkills();
  }

  searchForSkills() {
    this.selectTrade(this.trades[0]);
    this.showSkills();
  }

  toggleSelectTradeSkill(tradeSkill) {
    const isFound = this.selectedTradeSkills.some((ts) => {
      if (ts.id === tradeSkill.id) {
        return true;
      }
      return false;
    });
    if (isFound === false) {
      this.tradeSkills = this.tradeSkills.map((s) => {
        if (s.id === tradeSkill.id) {
          return { ...s, selected: true };
        }
        return s;
      });
      this.selectedTradeSkills.push({ ...tradeSkill, selected: true });
      this.showSkills();
    } else {
      {
        this.tradeSkills = this.tradeSkills.map((s) => {
          if (s.id === tradeSkill.id) {
            return { ...s, selected: false };
          }
          return s;
        });
        this.selectedTradeSkills = this.selectedTradeSkills.filter(
          (s) => s.id != tradeSkill.id
        );
      }
      this.showSkills();
    }
  }

  async showModal(selectedTradeSkills = []) {
    this.selectedTradeSkills = [...selectedTradeSkills];
    const response = await API.getTradeSkills();
    if (response.data.success) {
      this.tradeSkills = response.data.trade_skills.map((ts) => ({
        ...ts,
        selected: false,
      }));
      this.showSkills();
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.show = true;
  }
  async saveModal() {
    this.selectedTradeSkills = await [
      ...new Map(
        this.selectedTradeSkills.map((ts) => [ts.skill.id, ts])
      ).values(),
    ];
    this.$emit(consts.ModalEvents.OK_EVENT, this.selectedTradeSkills);
    this.show = false;
  }
  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }

  async created() {
    const response = await API.getTrades();
    if (response.data.success) {
      this.trades = [
        { id: 0, name: "All Skills", code: "", color: "" },
        ...response.data.trades,
      ];
      this.selectedTrade = this.trades[0];
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }
}
