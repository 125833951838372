
import { Vue, Component, Prop } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component
export default class TechnicianLocationForm extends Vue {
  @Prop({ default: "Enter the address" }) address;

  currentValue: any;
  edit = false;
  federalStates = [];
  countries = [];

  get buttonLabel() {
    return this.edit ? "Confirm" : "Edit";
  }

  public async editBtn() {
    this.edit = !this.edit;
  }

  selectCountry(e: string) {
    this.currentValue.country = e;
  }

  selectFederalState(e: string) {
    this.currentValue.federal_state = e;
  }

  async created() {
    this.currentValue = this.address;
    let response = await API.getFederalStates();
    if (response.data.success) {
      this.federalStates = response.data.federal_states.map(
        (state) => state.name
      );
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    response = await API.getCountries();
    if (response.data.success) {
      this.countries = response.data.countries.map((c) => c.name);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }
}
