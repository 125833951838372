import Vue from 'vue';
import izi from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import * as consts from '@/consts';
import * as API from '@/store/api';
import moment, { max } from 'moment';
import AuthModule from "@/store/modules/auth";

export const onlineHelper = Vue.observable({
  isOnline: navigator.onLine,
});

console.log('Add event listener online/offline');
window.addEventListener('online', () => {
  onlineHelper.isOnline = true;
  console.log('ONLINE listener');
});
window.addEventListener('offline', () => {
  onlineHelper.isOnline = false;
  console.log('OFFLINE listener');
});

export function statusArrAsDefaultMultiselect(arr: any[]) {
  return arr.map(el => {
    return { id: el.id, name: el.name };
  });
}

export async function getDictAsArray(id: number) {
  const response = await API.fetchDictType(id);
  const arr = [];
  response.data.objs.forEach(element => {
    arr.push(Object.assign({}, element));
  });
  return arr;
}

export function info(message: string, timeout = 2000) {
  izi.show({
    message: message,
    title: 'Info',
    position: 'center',
    timeout: timeout,
    color: 'green',
  });
}

export function warn(message: string, timeout = 2000) {
  izi.show({
    message: message,
    title: 'Warning',
    position: 'center',
    timeout: timeout,
    color: 'yellow',
  });
}

export function error(message: string) {
  izi.show({
    message: message,
    title: 'Error',
    position: 'center',
    timeout: 2000,
    color: 'red',
  });
}

export function isValidNip(nip: string) {
  //    if (typeof nip !== "string")
  //        return false;

  nip = nip.replace(/[\\-]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
}

export function isValidPostalCode(postalCode: string) {
  const regex = /\d{2}-\d{3}/g;
  return postalCode.match(regex);
  //return new RegExp(regex).test(postalCode);
}

export function setTempId(array: object[], obj: object) {
  console.log('setTempId', array, obj);
  const maxId = Math.max(...array.map(o => o['id']));
  if (obj['id'] === 0 || obj['id'] === -1) {
    if (maxId < consts.NEW_ID_START) {
      obj['id'] = consts.NEW_ID_START;
    } else {
      obj['id'] = maxId + 1;
    }
  }
}

export function getServerId(array: object[], obj: object) {
  console.log('getServerID', obj);
  if (array.length > 0) {
    const maxId = Math.max(...array.filter(el => el['id'] < consts.NEW_ID_START).map(o => o['id']));
    console.log('NewID=', maxId + 1);
    return maxId + 1;
  } else {
    return 1;
  }
}

export function createNumber(id: number) {
  return id.toString() + '/' + moment().year().toString();
}

export function truncate(value: any, max: number) {
  if (value.toString().length > max) {
    return parseInt(value.toString().slice(0, max));
  }
  return value;
}

export async function getCurrentUser() {
  const response = await API.fetchCurrUser();
  if (response.data.success === true) {
    return response.data.user;
  }
}

export function getAccountTypeStr(type: number): string {
  switch (type) {
    case consts.UserType.TGA_MANAGER:
      return consts.UserTypeStr.TGA_MANAGER;
    case consts.UserType.TGA_ADMIN:
      return consts.UserTypeStr.TGA_ADMIN;
    case consts.UserType.TECHNICIAN:
        return consts.UserTypeStr.TECHNICIAN;
    case consts.UserType.CUSTOMER_ADMIN:
      return consts.UserTypeStr.CUSTOMER_ADMIN;
    case consts.UserType.CUSTOMER_USER:
        return consts.UserTypeStr.CUSTOMER_USER;
    case consts.UserType.FACILITY_MANAGER:
        return consts.UserTypeStr.FACILITY_MANAGER;
    default:
      return "Company";
  }
}

export function getUserType(typeStr: string): number {
  switch (typeStr) {
    case consts.UserTypeStr.TGA_ADMIN:
      return consts.UserType.TGA_ADMIN;
    case consts.UserTypeStr.TGA_MANAGER:
      return consts.UserType.TGA_MANAGER;
    case consts.UserTypeStr.TECHNICIAN:
        return consts.UserType.TECHNICIAN;
    case consts.UserTypeStr.CUSTOMER_ADMIN:
      return consts.UserType.CUSTOMER_ADMIN;
    case consts.UserTypeStr.FACILITY_MANAGER:
        return consts.UserType.FACILITY_MANAGER; 
    case consts.UserTypeStr.CUSTOMER_USER:
        return consts.UserType.CUSTOMER_USER;
    default:
      return null;
  }}

  export function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] == value);
  }

  export function isUserLoggedIn(): boolean {
    return AuthModule.currentUser.username !== undefined
  }

  export function isUserPermitted(userTypes: number[]): boolean {
    return userTypes.includes(AuthModule.currentUser.user_type)
  }

  export function isLoggedIn() {
    const obj = {TGAManager: false, TGAAdmin: false, Customer: false}
    switch (AuthModule.currentUser.user_type) {
      case consts.UserType.TGA_MANAGER:
        obj.TGAManager = true;
        break
      case consts.UserType.TGA_ADMIN:
        obj.TGAAdmin = true;
        break
      default:
        obj.Customer = true;
    }
  return obj
  }