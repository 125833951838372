
import { Component, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TechnicianCard from "@/components/spec/TechnicianCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

enum FilterTypes {
  INPUT = "input",
  SERVICE_AREA = "serviceArea",
  TRADE = "trade",
  SKILL = "skill",
}

@Component({
  components: {
    TechnicianCard,
    TopPanel,
  },
})
export default class Technicians extends Vue {
  technicians = [];
  filteredTechsArray = [];
  filterInput = "";
  serviceArea = [];
  serviceAreaId = null;
  serviceAreas = [];
  skill = [];
  skillId = null;
  tradeSkills = [];
  skills = [];
  trade = [];
  tradeId = null;
  trades = [];
  filterButtons = [
    { type: FilterTypes.INPUT, value: "", selected: false },
    { type: FilterTypes.SERVICE_AREA, value: "", selected: false },
    { type: FilterTypes.TRADE, value: {}, selected: false },
    { type: FilterTypes.SKILL, value: "", selected: false },
  ];
  filterTypeTrade = FilterTypes.TRADE;
  filterTypeInput = FilterTypes.INPUT;

  get filterButtonsTrue() {
    return this.filterButtons.filter((fb) => fb.selected === true);
  }

  getTradeColor(tradeCode) {
    return this.trades.find((t) => t.code === tradeCode).color;
  }

  addNewTechnician() {
    this.$router.push("technician");
  }

  selectServiceArea(e: any) {
    this.serviceArea = e;
    this.serviceAreaId = this.serviceArea["id"];
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === FilterTypes.SERVICE_AREA) {
        return { ...fb, value: this.serviceArea["name"], selected: true };
      }
      return fb;
    });
    this.filterTechnicians();
  }

  selectTrade(e) {
    this.trade = e;
    this.tradeId = this.trade["id"];
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === FilterTypes.TRADE) {
        return {
          ...fb,
          value: { code: this.trade["code"], name: this.trade["name"] },
          selected: true,
        };
      }
      return fb;
    });
    this.filterTechnicians();
    this.getSkills();
  }

  getSkills() {
    if (this.tradeId === null) {
      this.skills = [];
      let allSkills = this.tradeSkills;
      allSkills = allSkills.map((ts) => ts.skill);
      this.skills = [...new Map(allSkills.map((s) => [s.name, s])).values()];
    } else {
      this.skills = this.tradeSkills.filter(
        (ts) => ts.trade.id === this.tradeId
      );
      this.skills = this.skills.map((ts) => ts.skill);
    }
  }

  selectSkill(e) {
    this.skill = e;
    this.skillId = this.skill["id"];
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === FilterTypes.SKILL) {
        return { ...fb, value: this.skill["name"], selected: true };
      }
      return fb;
    });
    this.filterTechnicians();
  }

  filterBySkillInput(technician) {
    for (const skill of technician.skills) {
      if (skill.name.toLowerCase().includes(this.filterInput.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  filterTechnicians() {
    let filteredTechsArray = this.technicians;
    if (
      this.filterInput !== "" ||
      this.serviceAreaId !== null ||
      this.skillId !== null ||
      this.tradeId !== null
    ) {
      if (this.filterInput !== "") {
        this.filterButtons = this.filterButtons.map((fb) => {
          if (fb.type === FilterTypes.INPUT) {
            return { ...fb, value: this.filterInput, selected: true };
          }
          return fb;
        });
        filteredTechsArray = filteredTechsArray.filter((tech) => {
          if (
            tech.username
              .toLowerCase()
              .includes(this.filterInput.toLowerCase()) ||
            tech.name.toLowerCase().includes(this.filterInput.toLowerCase()) ||
            this.filterBySkillInput(tech)
          ) {
            return true;
          }
        });
        this.filteredTechsArray = filteredTechsArray;
      }
      if (this.serviceAreaId !== null) {
        this.filterButtons["selected"] = true;
        filteredTechsArray = filteredTechsArray.filter((tech) => {
          for (const sa of tech.service_areas) {
            if (sa.id === this.serviceAreaId) {
              return true;
            }
          }
        });
        this.filteredTechsArray = filteredTechsArray;
      }
      if (this.skillId !== null) {
        filteredTechsArray = filteredTechsArray.filter((tech) => {
          for (const skill of tech.skills) {
            if (skill.id === this.skillId) {
              return true;
            }
          }
        });
        this.filteredTechsArray = filteredTechsArray;
      }
      if (this.tradeId !== null) {
        filteredTechsArray = filteredTechsArray.filter((tech) => {
          for (const skill of tech.skills) {
            for (const ts of this.tradeSkills.filter(
              (ts) => ts.trade.id === this.tradeId
            )) {
              if (skill.id === ts.skill.id) {
                return true;
              }
            }
          }
        });
        this.filteredTechsArray = filteredTechsArray;
      }
    } else {
      this.filteredTechsArray = this.technicians;
    }
    this.$forceUpdate();
  }

  deleteOneFilter(filterType) {
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === filterType) {
        if (fb.type === FilterTypes.TRADE) {
          return { ...fb, value: {}, selected: false };
        } else {
          return { ...fb, value: "", selected: false };
        }
      }
      return fb;
    });
    switch (filterType) {
      case FilterTypes.SERVICE_AREA:
        this.serviceArea = [];
        this.serviceAreaId = null;
        break;
      case FilterTypes.TRADE:
        this.trade = [];
        this.tradeId = null;
        break;
      case FilterTypes.SKILL:
        this.skill = [];
        this.skillId = null;
        break;
      default:
        this.filterInput = "";
    }
    this.filterTechnicians();
    this.getSkills();
  }

  clearFilters() {
    this.filterInput = "";
    this.serviceArea = [];
    this.serviceAreaId = null;
    this.trade = [];
    this.tradeId = null;
    this.skill = [];
    this.skillId = null;
    this.filterButtons = this.filterButtons.map((fb) => {
      if (fb.type === FilterTypes.TRADE) {
        return { ...fb, value: {}, selected: false };
      } else {
        return { ...fb, value: "", selected: false };
      }
    });
    this.filterTechnicians();
    this.getSkills();
  }

  async created() {
    API.getTechnicians().then((res) => {
      this.technicians = res.data.technicians;
      this.filteredTechsArray = this.technicians;
    });

    let response = await API.getServiceAreas();
    if (response.data.success) {
      this.serviceAreas = response.data.service_areas.map((area) => ({
        ...area,
        code_name: area.code + " - " + area.name,
      }));
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    response = await API.getTrades();
    if (response.data.success) {
      this.trades = response.data.trades;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    response = await API.getTradeSkills();
    if (response.data.success) {
      this.tradeSkills = response.data.trade_skills;
      this.skills = this.tradeSkills.map((ts) => ts.skill);
      this.getSkills();
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TECHNICIANS.charAt(0).toUpperCase() +
            consts.NavNames.TECHNICIANS.slice(1),
          name: "",
        },
      ],
    });
  }
}
