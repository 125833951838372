
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouterNames } from "@/consts";
import Avatar from "@/components/spec/Avatar.vue";
import * as userHelper from "@/helpers/user";

const SKILLS_T0_SHOW_COUNT = 2;

@Component({
  components: {
    Avatar,
  },
})
export default class TechnicianCard extends Vue {
  @Prop() technician;
  skillsToShowCount = SKILLS_T0_SHOW_COUNT;

  get additionalSkillsCount() {
    return this.technician.skills.length - SKILLS_T0_SHOW_COUNT;
  }

  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.technician.id);
  }

  goToProfile() {
    this.$router.push({
      path: RouterNames.TECHNICIAN,
      name: RouterNames.TECHNICIAN,
      params: {
        user_id: this.technician.id.toString(),
      },
    });
  }
}
