
import { Component, Ref, Vue } from "vue-property-decorator";
import ModalSkillsTechnician from "@/components/spec/ModalSkillsTechnician.vue";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ServiceAreas from "@/components/spec/ServiceAreas.vue";
import SuccessfullyCreated from "@/components/spec/SuccessfullyCreated.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Address, User } from "@/types";

@Component({
  components: {
    ModalSkillsTechnician,
    ServiceAreas,
    SuccessfullyCreated,
    TopPanel,
  },
})
export default class NewTechnician extends Vue {
  @Ref() readonly modalSkillsTechnician: ModalSkillsTechnician;
  saved = false;
  btnSave = 0;
  routingFinish = "";
  personalId = null;
  userType = consts.UserType.TECHNICIAN;
  user: User = new User();
  address: Address = new Address();
  userExists = false;

  federalState = [];
  federalStateId = null;
  federalStates = [];
  country = [];
  countryId = null;
  countries = [];
  selectedServiceAreas = [];
  selectedTradeSkills = [];

  // isServiceAreaFilled = true;
  // showSkills = true;
  // showServiceAreas = true;
  showServiceAreas = false;
  isServiceAreaFilled = false;
  showSkills = false;

  get isBasicsLocationFilled() {
    return this.isBasicsFilled && this.isAddressFilled && !this.userExists;
  }

  get isBasicsFilled() {
    return (
      this.user.username !== "" &&
      this.user.email !== "" &&
      this.federalStateId !== null &&
      this.user.first_name !== "" &&
      this.user.last_name !== "" &&
      this.user.mobile_phone !== ""
    );
  }

  get isAddressFilled() {
    return (
      this.address.street !== "" &&
      this.address.house_no !== "" &&
      this.address.city !== "" &&
      this.address.postal_code !== "" &&
      this.countryId !== null
    );
  }

  get isNextButtonActive() {
    if (this.showServiceAreas === false && this.showSkills === false) {
      return this.isBasicsLocationFilled;
    } else {
      return this.selectedServiceAreas.length > 0;
    }
  }

  get moreSkills() {
    return this.selectedTradeSkills.length - 30;
  }

  goNext() {
    if (this.showServiceAreas === false && this.showSkills === false) {
      this.showServiceAreas = true;
    } else {
      this.showSkills = true;
    }
  }

  async validateUsername() {
    if (this.user.username !== "") {
      const response = await API.validateUsername(this.user.username);
      if (response.data.success === false && "error_code" in response.data) {
        console.log("Username already exists");
        this.userExists = true;
      } else if (response.data.success === false) {
        helpers.error(
          "Unexpected server error - cannot verify if user already exists"
        );
      } else if (response.data.success === true) {
        this.userExists = false;
      }
    }
  }

  selectFederalState(e: any) {
    this.federalState = e;
    this.federalStateId = this.federalState["id"];
    console.log("selected", this.federalState);
  }

  selectCountry(e: any) {
    this.country = e;
    this.countryId = this.country["id"];
    console.log("selected", this.country);
  }

  async showModalSkillsTechnician(selectedTradeSkills) {
    await this.modalSkillsTechnician.showModal(selectedTradeSkills);
  }

  addSkills(selectedTradeSkills) {
    this.selectedTradeSkills = selectedTradeSkills;
    const selectedSkills = [];
    this.selectedTradeSkills.forEach((sts) => {
      if (!selectedTradeSkills.includes(sts.skill.id)) {
        selectedSkills.push(sts.skill);
      }
    });
    this.user["skills"] = selectedSkills;
  }

  updateServiceAreas(e) {
    this.selectedServiceAreas = e;
    console.log("update service areas", this.selectedServiceAreas);
  }

  async saveTechnician() {
    this.user["userType"] = this.userType;
    this.user["service_areas"] = this.selectedServiceAreas;
    this.address["federal_state"] = this.federalStateId;
    this.address["country"] = this.countryId;
    this.user.address = this.address;
    console.log("technician to save", this.user);
    const response = await API.saveUser(this.user, consts.TGACompany.ID);

    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.USER_EMAIL_EXISTS
    ) {
      console.log("User email already exists");
      helpers.error("User email already exists, please use another");
      this.btnSave++;
      return;
    }
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.USER_EXISTS
    ) {
      console.log("Username already exists");
      helpers.error("Username already exists, please use another");
      this.btnSave++;
      return;
    }
    if (response.data.success) {
      this.personalId = response.data.user.id;
      this.saved = true;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async created() {
    API.getPersonalId().then((res) => {
      this.personalId = res.data.id;
    });

    let response = await API.getFederalStates();
    if (response.data.success) {
      this.federalStates = response.data.federal_states;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    response = await API.getCountries();
    if (response.data.success) {
      this.countries = response.data.countries;
      this.selectCountry(this.countries[0]);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.routingFinish = this.$route.params.routingFinish;
    this.routingFinish =
      this.routingFinish === undefined
        ? consts.NavNames.TECHNICIANS
        : this.routingFinish;

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TECHNICIANS.charAt(0).toUpperCase() +
            consts.NavNames.TECHNICIANS.slice(1),
          name: consts.NavNames.TECHNICIANS,
        },
        {
          title: consts.BreadcrumbTitles.CREATE_TECHNICIAN,
          name: "",
        },
      ],
    });
  }
}
