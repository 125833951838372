var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"services container-fluid px-5 pb-5 pt-3"},[_c('div',{staticClass:"row m-0 p-0"},[_c('TopPanel',{staticClass:"col-12"})],1),_c('div',{staticClass:"row m-0 p-0 mt-2"},[_c('h3',[_vm._v("Dashboard")]),_c('div',{staticClass:"d-flex justify-content-right ml-auto"},[_c('a',{staticClass:"mr-3 p-link-dark",on:{"click":_vm.showModal}},[_c('h3',[_vm._v("Modal")])]),_c('a',{staticClass:"mr-3 p-link-dark",on:{"click":_vm.goToTest}},[_c('h3',[_vm._v("Test")])]),_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"row m-0 p-0 mt-3 d-flex align-content-between"},[_vm._m(2),_c('div',{staticClass:"col-5 technicians m-0 p-0"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-header"},[_vm._v("Technicians")]),_c('hr',{staticClass:"mx-4 mt-0"}),_c('table',{staticClass:"table"},[_vm._m(3),_c('tbody',_vm._l((_vm.services),function(service){return _c('tr',{key:service.id},[_vm._m(4,true),_c('td',{staticClass:"name"},[_vm._v(_vm._s(service.technician))]),_c('td',{staticClass:"id"},[_vm._v(_vm._s(service.id))]),_c('td',{staticClass:"location"},[_vm._v(_vm._s(service.location))]),_c('td',{staticClass:"customer"},[_vm._v(_vm._s(service.company))]),_c('td',{staticClass:"trade"},[_vm._v(_vm._s(service.trade))]),_c('td',{staticClass:"status"},[_vm._v(" "+_vm._s(service.status)+" ")]),_c('td',{staticClass:"details"},[_vm._v("Details ->")])])}),0)])])]),_vm._m(5)]),_c('modal-add-technician-event',{ref:"testModal",on:{"modal_ok":_vm.eventModalSave}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white-button mr-4"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/blue-plus-sign.svg")}}),_vm._v(" Contract ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"create-button"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/plus-sign.svg")}}),_vm._v(" Service ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-4 latest ml-0 p-0"},[_c('div',{staticClass:"panel ml-0"},[_c('div',{staticClass:"panel-header"},[_vm._v("Latest")]),_c('hr',{staticClass:"mx-3 mt-0"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',{staticClass:"thead"},[_c('tr',[_c('td',{staticClass:"pic"}),_c('td',{staticClass:"name"},[_vm._v("Name")]),_c('td',{staticClass:"id"},[_vm._v("Service ID")]),_c('td',{staticClass:"location"},[_vm._v("Location")]),_c('td',{staticClass:"customer"},[_vm._v("Customer")]),_c('td',{staticClass:"trade"},[_vm._v("Trade")]),_c('td',{staticClass:"status"},[_vm._v("Status")]),_c('td',{staticClass:"details"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/sample-account-picture.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-3 services m-0 pr-0"},[_c('div',{staticClass:"panel mr-0"},[_c('div',{staticClass:"panel-header"},[_vm._v("Scheduled services")]),_c('hr',{staticClass:"mx-4 mt-0 mb-0"}),_c('div',{staticClass:"w-100"},[_c('img',{staticStyle:{"height":"640px","width":"100%"},attrs:{"src":require("@/assets/map1.png")}})])])])
}]

export { render, staticRenderFns }