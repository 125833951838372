import { render, staticRenderFns } from "./ModalDelete.vue?vue&type=template&id=1c2800cb&scoped=true&"
import script from "./ModalDelete.vue?vue&type=script&lang=ts&"
export * from "./ModalDelete.vue?vue&type=script&lang=ts&"
import style0 from "./ModalDelete.vue?vue&type=style&index=0&id=1c2800cb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2800cb",
  null
  
)

export default component.exports