
import { Component, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TechnicianCard from "@/components/spec/TechnicianCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";

@Component({
  components: {
    TechnicianCard,
    TopPanel,
  },
})
export default class Services extends Vue {
  services = [];

  async created() {
    API.getServices().then((res) => {
      this.services = res.data.services;
    });
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.SERVICES.charAt(0).toUpperCase() +
            consts.NavNames.SERVICES.slice(1),
          name: "",
        },
      ],
    });
  }
}
