
import { Component, Vue, Ref } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import TechnicianCard from "@/components/spec/TechnicianCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import ModalAddTechnicianEvent from "@/components/spec/ModalAddTechnicianEvent.vue";

@Component({
  components: {
    TechnicianCard,
    TopPanel,
    ModalAddTechnicianEvent,
  },
})
export default class Dashboard extends Vue {
  @Ref() readonly testModal: ModalAddTechnicianEvent;
  services = [];

  async eventModalSave(event) {
    alert( event);
  }

  async created() {
    API.getServices().then((res) => {
      this.services = res.data.services;
    });
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [],
    });
  }
  goToTest() {
    this.$router.push({ name: "test" });
  }

  // nie usuwajmy tej funkcji do zakonczenia prac, mozna tu podpiac dowolnego modal-a
  showModal() {
    this.testModal.showModal();
  }
}
