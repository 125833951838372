import authModule from "@/store/modules/auth";
import * as API from "@/store/api";

export async function uploadUserAvatar(imageFile, user = null) {
  let currentUser;
  if (user === null) {
    currentUser = JSON.stringify(authModule.currentUser);
  } else {
    currentUser = user;
  }
  console.log("Current User", currentUser);

  const formData = new FormData();
  formData.append("user_id", currentUser.id);
  formData.append("file", imageFile);
  formData.append("filename", imageFile.name);
  const response = await API.uploadUserAvatar(formData);
}

export async function loadUserAvatar(user_id = null) {
  console.log("Test loadMethod", user_id);
  const response = await API.getUserData(user_id === null ? authModule.currentUser.id : user_id);
  const user_data = response.data.user_data;
  if (user_data.avatar.length > 0) {
    return "data:image/jpg;base64," + user_data.avatar;
  } else {
    return "";
  }
}

export async function removeUserAvatar(user_id) {
  console.log("RemoveUserAvatar");
  await API.removeUserAvatar(user_id === null ? authModule.currentUser.id : user_id);
}
