
import { Component, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component({})
export default class ModalWorkType extends Vue {
  show = false;
  btnSave = 0;
  color = "";

  showModal(color) {
    this.color = color;
    this.show = true;
  }

  async saveModal() {
    const response = await API.saveColor(this.color);
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.COLOR_EXISTS
    ) {
      console.log("Color already exists");
      helpers.error("Color already exists, please choose another");
      this.btnSave++;
      return;
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.show = false;
  }
}
