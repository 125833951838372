
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import CompanyLocationForm from "@/components/spec/CompanyLocationForm.vue";
import CustomerContracts from "@/components/spec/CustomerContracts.vue";
import EmployeeCard from "@/components/spec/EmployeeCard.vue";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ProfileDetailFormLine from "@/components/spec/ProfileDetailFormLine.vue";
import ServiceHistoryLine from "@/components/spec/ServiceHistoryLine.vue";
import TechnicianCard from "@/components/spec/TechnicianCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Company, Address, User } from "@/types";
import Avatar from "@/components/spec/Avatar.vue";
import * as companyHelper from "@/helpers/company";

@Component({
  components: {
    CompanyLocationForm,
    CustomerContracts,
    EmployeeCard,
    ModalDelete,
    ProfileDetailFormLine,
    ServiceHistoryLine,
    TechnicianCard,
    TopPanel,
    Avatar,
  },
})
export default class CustomerCompanyDetail extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  company: Company = new Company();
  address: Address = new Address();
  employees: User[] = [];
  firstEmployees: User[] = [];
  companyId = null;
  addressId = null;
  companyAccountType = consts.AccountType.COMPANY;
  services = [
    {
      id: "1",
      Location: "Berlin",
      Date: "17.02.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
    {
      id: "2",
      Location: "Berlin",
      Date: "22.04.2021",
      Status: "Finished",
      DotColor: "green",
      BgColor: "gray",
    },
    {
      id: "3",
      Location: "Berlin",
      Date: "12.05.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
  ];

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  get goBackRouter() {
    if (this.isLoggedIn.Customer === true) {
      return consts.NavNames.DASHBOARD;
    } else {
      return consts.NavNames.CUSTOMER_COMPANIES;
    }
  }

  async uploadCompanyAvatar(imageFile) {
    await companyHelper.uploadCompanyAvatar(imageFile, this.company);
  }
  async loadCompanyAvatar() {
    return await companyHelper.loadCompanyAvatar(this.companyId);
  }
  async removeCompanyAvatar() {
    await companyHelper.removeCompanyAvatar(this.companyId);
  }

  employeesCount() {
    return this.employees.length;
  }

  async handleUpdate($emit, value) {
    console.log("emit", $emit, "value", value);
    console.log("company", this.company);
    if ($emit === undefined) {
      console.log("new address", value);
      await API.saveAddress(value, this.address.id);
    } else {
      const key = helpers.getKeyByValue(this.company, value);
      this.company[key] = $emit;
      console.log("new company field", $emit);
      const response = await API.saveCompanyField(
        this.company[key],
        key,
        this.companyId
      );
      if (response.data.success === false) {
        if (response.data.error_code === consts.ErrorCodes.COMPANY_NIP_EXISTS) {
          console.log("Company number already exists");
          helpers.error("Company number already exists, please use another");
          return;
        } else {
          helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        }
      }
    }
  }

  routingHandle(path: string) {
    this.$router.push({
      path: `/mainView/${path}`,
      name: path,
      params: {
        routingFinish: "company",
        company_id: String(this.company["id"]),
      },
    });
  }

  showAllEmployees() {
    this.$router.push({
      path: "accounts",
      name: "accounts",
      query: {
        userType: [
          consts.NavNames.CUSTOMER_ADMINS,
          consts.NavNames.CUSTOMER_USERS,
          consts.NavNames.FACILITY_MANAGERS,
        ],
      },
      params: {
        company_id: this.company.id.toString(),
      },
    });
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteCompany() {
    const response = await API.deleteCompany(this.companyId);
    if (response.data.success) {
      helpers.info("Company and its users have been deleted");
      this.$router.push({
        path: `/mainView/${consts.NavNames.CUSTOMER_COMPANIES}`,
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async created() {
    this.companyId = this.$route.params.company_id;

    let response = await API.getCompany(this.companyId);
    if (response.data.success) {
      this.company = response.data.company;
      this.address = this.company.correspond_address;
      console.log("company", this.company, "address", this.address);

      response = await API.getCustomerManagersUsers(this.companyId);
      if (response.data.success) {
        this.employees = response.data.users;
        console.log("employees", this.employees);
        if (this.employees.length > 3) {
          this.firstEmployees = this.employees.slice(0, 3);
        } else {
          this.firstEmployees = this.employees;
        }
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.CUSTOMER_COMPANIES.charAt(0).toUpperCase() +
            consts.NavNames.CUSTOMER_COMPANIES.slice(1),
          name: consts.NavNames.CUSTOMER_COMPANIES,
        },
        {
          title: this.company.name,
          name: "",
        },
      ],
    });
  }
}
