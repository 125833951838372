
import { Component, Vue } from "vue-property-decorator";
import NavView from "@/components/spec/NavView.vue";

@Component({
  components: {
    NavView,
  },
})
export default class MainView extends Vue {
  height = 128;
}
