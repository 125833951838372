
import { Vue, Component, Prop } from "vue-property-decorator";
import Contract from "@/components/spec/Contract.vue";

@Component({
  components: {
    Contract,
  },
})
export default class CustomerContracts extends Vue {
  @Prop({ default: "default main label" }) mainLabel!: string;
  @Prop({ default: "0" }) numberOfContracts!: string | number;
  @Prop({ default: false }) first!: boolean;
}
