import axios, { AxiosInstance } from "axios";
import store from "@/store";
import { User } from "@/types";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  namespaced: true,
  name: "auth",
  store,
  dynamic: true,
})
class AuthModule extends VuexModule {
  auth = {
    authenticated: false,
    user: {} as User,
  };

  get currentUser() {
    return this.auth.user;
  }

  get authenticatedAxios() {
    return (isFormData = false): AxiosInstance => {
      axios.defaults.xsrfCookieName = "csrftoken";
      axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

      const headers = {
        Authorization: `Token ${this.auth.user.token}`,
        //"Content-Type": "application/json",
      };

      if (isFormData === true) {
        headers["Content-type"] = "multipart/form-data;boundary=63c5979328c44e2c869349443a94200e";
      }

      const authAxios = axios.create({
        baseURL: process.env.VUE_APP_API_URL + "api/",
        headers: headers,
        /*
        transformRequest: [
          (data, headers) => {
            if (data !== undefined) {
              if (data["uid"] !== undefined) {
                console.error("You can not use uid in data object.");
              } else {
                data["uid"] = this.auth.user.id;
                return JSON.stringify(data);
              }
            } else {
              return JSON.stringify({ uid: this.auth.user.id });
            }
          },
        ],
        */
      });

      return authAxios as AxiosInstance;
    };
  }

  get notAuthenticatedAxios() {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

    const headers = {
      "Content-Type": "application/json",
    };
    console.log("process.env.VUE_APP_API_URL", process.env.VUE_APP_API_URL);
    const authAxios = axios.create({
      baseURL: process.env.VUE_APP_API_URL + "api/",
      headers: headers,
    });

    return authAxios;
  }

  @Mutation
  setAuthenticated(user: User) {
    this.auth.authenticated = true;
    console.log("SET AUTH USER", user);
    this.auth.user = { ...user } as User;
  }
}

export default getModule(AuthModule);
