
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component({})
export default class ModalSkillsWorkType extends Vue {
  @Prop({ type: Object, required: true }) trade;
  @Prop({ type: Number, required: true }) workTypeId;
  show = false;
  btnSave = 0;
  filterInput = "";
  skills = [];
  filteredSkills = [];
  selectedSkills = [];

  get skillsCurrentlySelected() {
    return this.skills.filter((s) => s.selected === true);
  }

  toggleSelectSkill(skill) {
    this.skills = this.skills.map((s) => {
      if (s.id === skill.id) {
        return { ...s, selected: !s.selected };
      }
      return s;
    });
    this.filteredSkills = this.filteredSkills.map((fs) => {
      if (fs.id === skill.id) {
        return { ...fs, selected: !fs.selected };
      }
      return fs;
    });
  }

  filterSkills() {
    let filteredSkills = this.skills;
    if (this.filterInput !== "") {
      filteredSkills = filteredSkills.filter((s) => {
        if (
          s.code.toLowerCase().includes(this.filterInput.toLowerCase()) ||
          s.name.toLowerCase().includes(this.filterInput.toLowerCase())
        ) {
          return true;
        }
      });
      this.filteredSkills = filteredSkills;
    } else {
      this.filteredSkills = this.skills;
    }
  }

  async showModal(selectedSkills = []) {
    this.selectedSkills = selectedSkills.map((s) => s.id);
    const response = await API.getTradeSkills();
    if (response.data.success) {
      this.skills = response.data.trade_skills.filter(
        (ts) => ts.trade.id === this.trade.id
      );
      this.skills = this.skills.map((ts) => {
        return {
          ...ts.skill,
          selected: this.selectedSkills.includes(ts.skill.id),
        };
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.filteredSkills = this.skills;
    this.show = true;
  }

  async saveModal() {
    const skillsToSave = this.skills.filter((s) => s.selected === true);
    const response = await API.saveWorkTypeSkills(
      this.workTypeId,
      skillsToSave
    );
    if (response.data.success === false) {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
