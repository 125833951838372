import axios, { AxiosResponse, AxiosInstance } from "axios";
import AuthModule from "./modules/auth";
import * as types from "@/types";

export const tgaApi = AuthModule.authenticatedAxios;
export const tgaNAApi = AuthModule.notAuthenticatedAxios;

/************************************** AUTH ****************************************/
export function setJWT(jwt: string) {
  console.log("JWT set");
  tgaApi().defaults.headers.common["Authorization"] = `Token ${jwt}`;
}

export function clearJWT() {
  delete tgaApi().defaults.headers.common["Authorization"];
}

export async function loginUser(user: types.UserSubmit) {
  const response = await tgaApi().post("/login", { user });
  console.log("api response", response);
  return response;
}

export async function reset(email: string): Promise<any> {
  console.log("BaseURL:", tgaApi().defaults.baseURL);
  const response = await tgaApi().post("/reset", { email });
  console.log("Response", response);
  return response;
}

/************************************ USERS ********************************/
export async function saveUser(
  user: any,
  companyId: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/save_user", { user, companyId });
}

export async function deleteUser(userId: number): Promise<AxiosResponse> {
  return await tgaApi().post("/delete_user", { userId });
}

export async function saveUserField(
  value: any,
  key: any,
  userId: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/save_user_field", { value, key, userId });
}

export async function validateUsername(
  username: string
): Promise<AxiosResponse> {
  return await tgaApi().get(`/validate_username/${username}`);
}

export async function fetchCurrUser(): Promise<AxiosResponse> {
  const response = await tgaApi().get("/get_curr_user");
  return response;
}

export async function getTGAManagers(): Promise<AxiosResponse> {
  return await tgaApi().get("/get_tga_managers");
}

export async function getCustomerManagersUsers(
  companyId: number
): Promise<AxiosResponse> {
  return await tgaApi().get(`/get_customer_managers_users/${companyId}`);
}

export async function getTGAManager(userId: number): Promise<AxiosResponse> {
  return await tgaApi().get(`/get_tga_manager/${userId}`);
}

export async function getCustomerManagerUser(
  userId: number
): Promise<AxiosResponse> {
  return await tgaApi().get(`/get_customer_manager_user/${userId}`);
}

export async function getPersonalId() {
  const response = await tgaApi().get("/get_personal_id");
  return response;
}

/************************************ DASHBOARDS ********************************/
export async function fetchDictType(dict_type: number): Promise<AxiosResponse> {
  return await tgaApi().post("/get_dict", { dict_type });
}

export async function getCustomers() {
  const response = await tgaApi().post("/get_customers");
  return response;
}
/************************************ SERVICES ********************************/
export async function getServices() {
  const response = await tgaApi().get("/services");
  return response;
}

export async function getTrades() {
  const response = await tgaApi().get("/trades");
  return response;
}

export async function getTrade(tradeId: number): Promise<AxiosResponse> {
  return await tgaApi().get(`/trades/${tradeId}`);
}

export async function getAvailableTradeColors() {
  return await tgaApi().get("/trades/get_available_colors");
}

export async function saveColor(color: string): Promise<AxiosResponse> {
  return await tgaApi().post("/trades/save_color", { color });
}

export async function getColors() {
  return await tgaApi().get("/trades/get_colors");
}

export async function deleteColor(colorId: number): Promise<AxiosResponse> {
  return await tgaApi().post("/trades/delete_color", { colorId });
}

export async function validateTemplateCode(
  code: string,
  templateType: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/validate_template_code", { code, templateType });
}

export async function saveTrade(trade: types.Trade): Promise<AxiosResponse> {
  return await tgaApi().post("/trades/save_trade", { trade });
}

export async function deleteTrade(tradeId: number): Promise<AxiosResponse> {
  return await tgaApi().post("/trades/delete_trade", { tradeId });
}

export async function getWorkTypes() {
  const response = await tgaApi().get("/work_types");
  return response;
}

export async function getWorkType(workTypeId: number): Promise<AxiosResponse> {
  return await tgaApi().get(`/work_types/${workTypeId}`);
}

export async function saveWorkType(
  workType: types.WorkType
): Promise<AxiosResponse> {
  return await tgaApi().post("/work_types/save_work_type", { workType });
}

export async function saveWorkTypeSkills(
  workTypeId: number,
  skills: types.Skill[]
): Promise<AxiosResponse> {
  const response = await tgaApi().post("/work_types/save_work_type_skills", {
    workTypeId,
    skills,
  });
  return response;
}

export async function deleteWorkType(
  workTypeId: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/work_types/delete_work_type", { workTypeId });
}

export async function getTaskLists(workTypeId: number): Promise<AxiosResponse> {
  const response = await tgaApi().get(`/task_lists/${workTypeId}`);
  return response;
}

export async function saveTaskList(
  taskList: types.TaskList
): Promise<AxiosResponse> {
  return await tgaApi().post("/task_lists/save_task_list", { taskList });
}

export async function deleteTaskList(
  taskListId: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/task_lists/delete_task_list", { taskListId });
}

export async function saveTask(task: types.Task): Promise<AxiosResponse> {
  return await tgaApi().post("/task_lists/save_task", { task });
}

export async function deleteTask(taskId: number): Promise<AxiosResponse> {
  return await tgaApi().post("/task_lists/delete_task", { taskId });
}

export async function saveSkill(skill: types.Skill): Promise<AxiosResponse> {
  return await tgaApi().post("/save_skill", { skill });
}

export async function deleteSkill(skillId: number): Promise<AxiosResponse> {
  return await tgaApi().post("/delete_skill", { skillId });
}

export async function deleteSkills(skillIds: number[]): Promise<AxiosResponse> {
  return await tgaApi().post("/delete_skills", { skillIds });
}

/************************************ TECHNICIANS ********************************/
export async function getTechnicians() {
  const response = await tgaApi().get("/technicians");
  return response;
}

export async function getTechnician(userId: number): Promise<AxiosResponse> {
  const response = await tgaApi().get(`/get_technician/${userId}`);
  return response;
}

export async function getSkills() {
  const response = await tgaApi().get("/skills");
  return response;
}

export async function saveUserSkills(
  userId: number,
  skills: types.Skill[]
): Promise<AxiosResponse> {
  const response = await tgaApi().post("/save_user_skills", { userId, skills });
  return response;
}

export async function saveUserServiceAreas(
  userId: number,
  serviceAreas: any[]
): Promise<AxiosResponse> {
  const response = await tgaApi().post("/save_user_service_areas", {
    userId,
    serviceAreas,
  });
  return response;
}

export async function getTradeSkills() {
  const response = await tgaApi().get("/trade_skills");
  return response;
}
/************************************ ACCOUNTS ********************************/
export async function getAccounts() {
  const response = await tgaApi().get("/accounts");
  return response;
}

export async function getAccount(user_id: number) {
  const response = await tgaApi().get(`/accounts/${user_id}`);
  return response;
}

export async function saveCompanyField(
  value: any,
  key: any,
  companyId: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/save_company_field", { value, key, companyId });
}

export async function saveCompany(company: any): Promise<AxiosResponse> {
  return await tgaApi().post("/save_company", company);
}

export async function deleteCompany(companyId: number): Promise<AxiosResponse> {
  return await tgaApi().post("/delete_company", { companyId });
}

export async function getCompany(companyId: number): Promise<AxiosResponse> {
  console.log("getCompany", companyId);
  return await tgaApi().get(`/get_company/${companyId}`);
}

export async function getCompanies(): Promise<AxiosResponse> {
  return await tgaApi().get("/get_companies");
}

export async function getCompanyId() {
  const response = await tgaApi().get("/get_company_id");
  return response;
}

export async function saveAddress(
  address: any,
  addressId: number
): Promise<AxiosResponse> {
  return await tgaApi().post("/save_address", { address, addressId });
}

export async function getAddress(addressId: number): Promise<AxiosResponse> {
  return await tgaApi().get(`/get_address/${addressId}`);
}

export async function getFederalStates(): Promise<AxiosResponse> {
  return await tgaApi().get("/get_federal_states");
}

export async function getServiceAreas(): Promise<AxiosResponse> {
  return await tgaApi().get("/get_service_areas");
}

export async function getCountries(): Promise<AxiosResponse> {
  return await tgaApi().get("/get_countries");
}

export async function uploadUserAvatar(data): Promise<AxiosResponse> {
  return await tgaApi().post("/upload_user_avatar", data, {
    headers: {
      "Content-Type":
        "multipart/form-data;boundary=63c5979328c44e2c869349443a94200e",
    },
  });
}

export async function getUserData(user_id) {
  const response = await tgaApi().get(`/get_user_data/${user_id}`);
  return response;
}

export async function removeUserAvatar(user_id) {
  const response = await tgaApi().delete(`/remove_user_avatar/${user_id}`);
  return response;
}

export async function uploadCompanyAvatar(data): Promise<AxiosResponse> {
  return await tgaApi().post("/upload_company_avatar", data, {
    headers: {
      "Content-Type":
        "multipart/form-data;boundary=63c5979328c44e2c869349443a94200e",
    },
  });
}

export async function getCompanyData(company_id) {
  const response = await tgaApi().get(`/get_company_data/${company_id}`);
  return response;
}

export async function removeCompanyAvatar(company_id) {
  const response = await tgaApi().delete(
    `/remove_company_avatar/${company_id}`
  );
  return response;
}
