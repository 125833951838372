import authModule from "@/store/modules/auth";
import * as API from "@/store/api";

export async function uploadCompanyAvatar(imageFile, company) {
  const formData = new FormData();
  console.log("uploadCompanyAvatar", company);
  formData.append("company_id", company.id);
  formData.append("file", imageFile);
  formData.append("filename", imageFile.name);
  const response = await API.uploadCompanyAvatar(formData);
}

export async function loadCompanyAvatar(company_id) {
  console.log("Test loadMethod");
  const response = await API.getCompanyData(company_id);
  const company_data = response.data.company_data;

  if (company_data.avatar.length > 0) {
    console.log("loadCompanyAvatar", company_data);
    return "data:image/jpg;base64," + company_data.avatar;
  } else {
    return "";
  }
}

export async function removeCompanyAvatar(company_id) {
  console.log("RemoveCompanyAvatar");
  await API.removeCompanyAvatar(company_id);
  console.log("Company's avatar removed", company_id);
}
