
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Trade } from "@/types";

@Component({})
export default class ModalTrade extends Vue {
  show = false;
  btnSave = 0;
  isNew = false;
  trade: Trade = new Trade();

  goToTrade() {
    this.$router.push({
      path: consts.RouterNames.TRADE,
      name: consts.RouterNames.TRADE,
      params: {
        trade_id: this.trade.id.toString(),
      },
    });
  }

  async showModal(trade: Trade = null) {
    if (trade === null) {
      this.isNew = true;
      this.trade = new Trade();
      const response = await API.getAvailableTradeColors();
      if (response.data.success) {
        this.trade.color = response.data.colors[0];
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        this.trade.color = "#000000";
      }
    } else {
      this.trade = trade;
    }
    this.show = true;
  }

  async saveModal() {
    const response = await API.saveTrade(this.trade);
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.CODE_EXISTS
    ) {
      console.log("Trade code already exists");
      helpers.error("Trade code already exists, please enter another");
      this.btnSave++;
      return;
    }
    if (response.data.success) {
      if (this.isNew === true) {
        this.trade.id = response.data.trade_id;
        this.goToTrade();
      }
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
