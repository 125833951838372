
import { Component, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import companyCard from "@/components/spec/CompanyCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";

@Component({
  components: {
    companyCard,
    TopPanel,
  },
})
export default class Companies extends Vue {
  companies = [];
  filterInput = "";
  filteredCompaniesArray = [];
  numberOfCompaniesByCriteria = 0;
  serviceArea = [];
  serviceAreaId = null;
  serviceAreas = [];
  federalState = [];
  federalStateId = null;
  federalStates = [];

  clearFilters() {
    this.filterInput = "";
    this.serviceArea = [];
    this.serviceAreaId = null;
    this.federalState = [];
    this.federalStateId = null;
    this.filterCompanies();
  }

  async filterCompanies() {
    const filterParams = [];
    if (this.filterInput !== "") {
      filterParams.push("text");
    }
    if (this.serviceAreaId !== null) {
      filterParams.push("area");
    }
    if (this.federalStateId !== null) {
      filterParams.push("state");
    }
    let result = true;

    const filteredCompaniesArray = this.companies.filter((company) => {
      result = true;
      filterParams.forEach((filter) => {
        if (result) {
          if (filter === "text") {
            result =
              company.name
                .toLowerCase()
                .includes(this.filterInput.toLowerCase()) ||
              company.nip.includes(this.filterInput);
            return result;
          }
          if (filter === "area") {
            result =
              company.correspond_address.service_area === this.serviceAreaId;
            return result;
          }
          if (filter === "state") {
            result =
              company.correspond_address.federal_state === this.federalStateId;
            return result;
          }
        }
      });
      return result;
    });
    this.numberOfCompaniesByCriteria = filteredCompaniesArray.length;
    this.filteredCompaniesArray = filteredCompaniesArray;
    this.$forceUpdate();
  }

  selectServiceArea(e: any) {
    this.serviceArea = e;
    this.serviceAreaId = this.serviceArea["id"];
    this.filterCompanies();
  }

  selectFederalState(e: any) {
    this.federalState = e;
    this.federalStateId = this.federalState["id"];
    this.filterCompanies();
  }

  routingHandle(path: string) {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
    });
  }

  async created() {
    await API.getCompanies().then((res) => {
      this.companies = res.data.companies;
      this.filteredCompaniesArray = this.companies;
      this.filterCompanies();
    });
    let response = await API.getServiceAreas();
    if (response.data.success) {
      this.serviceAreas = response.data.service_areas.map((area) => ({
        ...area,
        code_name: area.code + " - " + area.name,
      }));
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    response = await API.getFederalStates();
    if (response.data.success) {
      this.federalStates = response.data.federal_states;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.CUSTOMER_COMPANIES.charAt(0).toUpperCase() +
            consts.NavNames.CUSTOMER_COMPANIES.slice(1),
          name: "",
        },
      ],
    });
  }
}
