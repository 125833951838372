
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Company, Trade, User, WorkType } from "@/types";
import Avatar from "@/components/spec/Avatar.vue";
import * as userHelper from "@/helpers/user";
import * as companyHelper from "@/helpers/company";

const USER_ACCOUNT_TYPE = consts.AccountType.USER;
const COMPANY_ACCOUNT_TYPE = consts.AccountType.COMPANY;
const TASK_LIST_TEMPLATE_TYPE = consts.TemplateType.TASK_LIST;

@Component({
  components: {
    Avatar,
  },
})
export default class ModalDelete extends Vue {
  @Prop({ type: Number, required: false, default: null }) accountId;
  @Prop({ type: Number, required: false, default: null }) templateId;
  @Prop({ type: Array, required: false, default: () => [] }) skillIds;
  @Prop({ type: Number, required: false, default: USER_ACCOUNT_TYPE })
  accountType;
  @Prop({ type: Number, required: false }) templateType;
  @Prop({ type: String, required: false }) templateTypeStr;

  company: Company = new Company();
  user: User = new User();
  trade: Trade = new Trade();
  workType: WorkType = new WorkType();
  userAccountType = USER_ACCOUNT_TYPE;
  companyAccountType = COMPANY_ACCOUNT_TYPE;
  taskListTemplateType = TASK_LIST_TEMPLATE_TYPE;
  show = false;
  btnSave = 0;

  get isAccountType() {
    return this.accountId !== null;
  }

  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.user.id);
  }

  async loadCompanyAvatar() {
    return await companyHelper.loadCompanyAvatar(this.company.id);
  }

  showModal() {
    this.show = true;
  }
  async saveModal() {
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }
  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }

  async created() {
    if (this.isAccountType === true) {
      if (this.accountType === COMPANY_ACCOUNT_TYPE) {
        const response = await API.getCompany(this.accountId);
        if (response.data.success) {
          this.company = response.data.company;
        } else {
          helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        }
      } else {
        const response = await API.getAccount(this.accountId);
        if (response.data.success) {
          this.user = response.data.user;
        } else {
          helpers.error(consts.SERVER_COMMUNICATION_ERROR);
        }
      }
    }
  }
}
