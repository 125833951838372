
import { Component, Vue, Prop } from "vue-property-decorator";
import appModule from "../../store/modules/app";
import { EventBus } from "../../helpers/eventbus";

@Component
export default class BreadcrumbPanel extends Vue {
  idx = 0;

  get breadcrumb() {
    this.idx;
    console.log(
      "BreadcrumbPanel::getter breadcrum idx=",
      this.idx,
      this.$store.state.breadcrumb.breadcrumbObj
    );
    return this.$store.state.breadcrumb.breadcrumbObj;
  }

  update() {
    console.log(
      "BreadcrumbPanel::breadcrumb updating...",
      this.idx,
      this.breadcrumb
    );
    this.idx++;
  }

  mounted() {
    console.log("Breadcrumb mounted");
    EventBus.$on("breadcrumb-update", this.update);
  }
  beforeDestroy() {
    console.log("Breadcrumb destroyed");
    EventBus.$off("breadcrumb-update");
  }
}
