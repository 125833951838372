
import { Component, Vue } from "vue-property-decorator";
import * as consts from "@/consts";

@Component({})
export default class ModalAddTechnicianEvent extends Vue {
  EventType = consts.TechnicianEventType;

  show = false;
  btnSave = 0;

  pickedEventType = "sick";
  startTime = null;
  endTime = null;
  date = null;

  async showModal() {
    this.show = true;
  }

  async saveModal() {
    this.$emit(consts.ModalEvents.OK_EVENT, {
      type: this.pickedEventType,
      startTime: this.startTime,
      endTime: this.endTime,
      date: this.date,
    });
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
