
import { Component, Prop, Vue } from "vue-property-decorator";
import * as consts from "@/consts";
import Avatar from "@/components/spec/Avatar.vue";
import * as companyHelper from "@/helpers/company";

@Component({
  components: {
    Avatar,
  },
})
export default class CompanyCard extends Vue {
  @Prop() company;

  async loadCompanyAvatar() {
    return await companyHelper.loadCompanyAvatar(this.company.id);
  }

  goToProfile() {
    this.$router.push({
      path: consts.RouterNames.COMPANY,
      name: consts.RouterNames.COMPANY,
      params: {
        company_id: this.company.id.toString(),
      },
    });
  }
}
