
import { Component, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import SuccessfullyCreated from "@/components/spec/SuccessfullyCreated.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { User } from "@/types";

@Component({
  components: {
    SuccessfullyCreated,
    TopPanel,
  },
})
export default class NewTGAManager extends Vue {
  saved = false;
  btnSave = 0;
  personalId = null;
  routingFinish = "";
  userType = consts.UserType.TGA_MANAGER;
  userTypeStr = helpers.getAccountTypeStr(this.userType);
  user: User = new User();
  userExists = false;

  get isAccountDetailsFilled() {
    return this.user.username !== "";
  }

  get isMasterDataFilled() {
    return (
      this.user.first_name !== "" &&
      this.user.email !== "" &&
      this.user.phone !== "" &&
      this.user.last_name !== ""
    );
  }

  get isSaveEnabled() {
    return (
      this.isAccountDetailsFilled && this.isMasterDataFilled && !this.userExists
    );
  }

  async saveManager() {
    this.user["userType"] = this.userType;
    console.log("TGA Manager to save", this.user);
    const response = await API.saveUser(this.user, consts.TGACompany.ID);

    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.USER_EMAIL_EXISTS
    ) {
      console.log("User email already exists");
      helpers.error("User email already exists, please use another");
      this.btnSave++;
      return;
    }
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.USER_EXISTS
    ) {
      console.log("Username already exists");
      helpers.error("Username already exists, please use another");
      this.btnSave++;
      return;
    }
    if (response.data.success) {
      this.personalId = response.data.user.id;
      this.saved = true;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async validateUsername() {
    if (this.user.username !== "") {
      const response = await API.validateUsername(this.user.username);
      if (response.data.success === false && "error_code" in response.data) {
        console.log("Username already exists");
        this.userExists = true;
      } else if (response.data.success === false) {
        helpers.error(
          "Unexpected server error - cannot verify if user already exists"
        );
      } else if (response.data.success === true) {
        this.userExists = false;
      }
    }
  }

  async created() {
    this.routingFinish = this.$route.params.routingFinish;
    API.getPersonalId().then((res) => {
      this.personalId = res.data.id;
    });
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.ACCOUNTS.charAt(0).toUpperCase() +
            consts.NavNames.ACCOUNTS.slice(1),
          name: consts.NavNames.ACCOUNTS,
        },
        {
          title: consts.BreadcrumbTitles.NEW_TGA_MANAGER,
          name: "",
        },
      ],
    });
  }
}
