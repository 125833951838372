
import { Component, Prop, Vue } from "vue-property-decorator";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Account } from "@/types";
import Avatar from "@/components/spec/Avatar.vue";
import * as userHelper from "@/helpers/user";

@Component({
  components: {
    Avatar,
  },
})
export default class EmployeeCard extends Vue {
  @Prop() account: Account;

  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.account.id);
  }

  get userTypeStr() {
    return helpers.getAccountTypeStr(this.account.user_data.user_type);
  }

  goToProfile() {
    let profile = "";
    const type = this.account.user_data.user_type;
    switch (type) {
      case consts.UserType.CUSTOMER_USER:
      case consts.UserType.CUSTOMER_ADMIN:
      case consts.UserType.FACILITY_MANAGER:
        profile = consts.RouterNames.CUSTOMER_USER;
        break;
      case consts.UserType.TGA_ADMIN:
      case consts.UserType.TGA_MANAGER:
        profile = consts.RouterNames.TGA_MANAGER;
        break;
      case consts.UserType.TECHNICIAN:
        profile = consts.RouterNames.TECHNICIAN;
        break;
    }
    this.$router.push({
      path: profile,
      name: profile,
      params: {
        user_id: this.account.id.toString(),
      },
    });
  }
}
