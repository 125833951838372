
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import Avatar from "@/components/spec/Avatar.vue";
import CustomerContracts from "@/components/spec/CustomerContracts.vue";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ProfileDetailFormLine from "@/components/spec/ProfileDetailFormLine.vue";
import ServiceHistoryLine from "@/components/spec/ServiceHistoryLine.vue";
import TitleFormLine from "@/components/spec/TitleFormLine.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import * as userHelper from "@/helpers/user";
import { User, Company } from "@/types";

@Component({
  components: {
    CustomerContracts,
    ModalDelete,
    ProfileDetailFormLine,
    ServiceHistoryLine,
    TitleFormLine,
    TopPanel,
    Avatar,
  },
})
export default class CustomerUserDetail extends Vue {
  @Ref() readonly modalDelete: ModalDelete;
  company: Company = new Company();
  user: User = new User();
  userId = Number(this.$route.params.user_id);

  services = [
    {
      id: "1",
      Location: "Berlin",
      Date: "17.02.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
    {
      id: "2",
      Location: "Berlin",
      Date: "22.04.2021",
      Status: "Finished",
      DotColor: "green",
      BgColor: "gray",
    },
    {
      id: "3",
      Location: "Berlin",
      Date: "12.05.2021",
      Status: "Finished",
      DotColor: "red",
      BgColor: "white",
    },
  ];

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  async uploadUserAvatar(imageFile) {
    await userHelper.uploadUserAvatar(imageFile, this.user);
  }
  async loadUserAvatar() {
    return await userHelper.loadUserAvatar(this.userId);
  }
  async removeUserAvatar() {
    await userHelper.removeUserAvatar(this.userId);
  }

  get userTypeStr() {
    try {
      return helpers.getAccountTypeStr(this.user.user_data.user_type);
    } catch {
      console.log("Cannot get userType - no user yet");
      return "";
    }
  }

  async handleUpdate($emit, value) {
    // TO-DO obsluzyc zmiane hasla, obecnie disabled
    console.log("emit", $emit, "value", value);
    const key = helpers.getKeyByValue(this.user, value);
    this.user[key] = $emit;
    const response = await API.saveUserField(this.user[key], key, this.userId);
    if (response.data.success === false) {
      if (response.data.error_code === consts.ErrorCodes.USER_EMAIL_EXISTS) {
        console.log("User email already exists");
        helpers.error("User email already exists, please use another");
        return;
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }
  }

  async handleUpdateTitleFormLine(user) {
    console.log("emit user", user);
    for (const value of [user.first_name, user.last_name, user.username]) {
      const key = helpers.getKeyByValue(user, value);
      await API.saveUserField(user[key], key, this.userId);
    }
  }

  async handleUserTypeUpdate(userTypeStr) {
    console.log("emit userType", userTypeStr);
    const key = "user_type";
    this.user.user_data[key] = helpers.getUserType(userTypeStr);
    await API.saveUserField(this.user.user_data[key], key, this.userId);
  }

  routingHandle() {
    this.$router.push({
      path: `/mainView/${consts.RouterNames.COMPANY}/${this.company.id}`,
    });
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async deleteUser() {
    const response = await API.deleteUser(this.user.id);
    if (response.data.success) {
      helpers.info("User account has been deleted");
      this.$router.push({
        path: consts.RouterNames.COMPANY,
        name: consts.RouterNames.COMPANY,
        params: {
          company_id: this.company.id.toString(),
        },
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async created() {
    const response = await API.getCustomerManagerUser(this.userId);
    if (response.data.success) {
      this.user = response.data.user;
      this.user.phone = this.user.user_data.phone;
      this.user.mobile_phone =
        this.user.user_data.mobile_phone === ""
          ? "None"
          : this.user.user_data.mobile_phone;
      this.company = response.data.company;
      console.log("user", this.user, "company", this.company);
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.ACCOUNTS.charAt(0).toUpperCase() +
            consts.NavNames.ACCOUNTS.slice(1),
          name: consts.NavNames.ACCOUNTS,
        },
        {
          title: this.user.first_name + " " + this.user.last_name,
          name: "",
        },
      ],
    });
  }
}
