
import { Component, Ref, Vue } from "vue-property-decorator";
import breadcrumbModule from "@/store/modules/breadcrumb";
import ModalAddColor from "@/components/spec/ModalAddColor.vue";
import ModalDelete from "@/components/spec/ModalDelete.vue";
import ModalTrade from "@/components/spec/ModalTrade.vue";
import ModalWorkType from "@/components/spec/ModalWorkType.vue";
import WorkTypeCard from "@/components/spec/WorkTypeCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Trade } from "@/types";

@Component({
  components: {
    ModalAddColor,
    ModalDelete,
    ModalTrade,
    ModalWorkType,
    WorkTypeCard,
    TopPanel,
  },
})
export default class TradeDetail extends Vue {
  @Ref() readonly modalAddColor: ModalAddColor;
  @Ref() readonly modalDelete: ModalDelete;
  @Ref() readonly modalTrade: ModalTrade;
  @Ref() readonly modalWorkType: ModalWorkType;
  tradeId = null;
  trade: Trade = new Trade();
  tradeTemplateType = [consts.TemplateType.TRADE, consts.TemplateTypeStr.TRADE];
  workTypes = [];
  filteredWorkTypes = [];
  filterInput = "";
  colors = [];
  availableColors = [];
  newColor = "#000000";
  timeoutToShow = null;
  timeoutToHide = null;
  overColor = false;

  get isLoggedIn() {
    return helpers.isLoggedIn();
  }

  async getTrade() {
    const response = await API.getTrade(this.tradeId);
    if (response.data.success) {
      this.trade = response.data.trade;
      this.workTypes = this.trade["work_types"];
      this.filteredWorkTypes = this.workTypes;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    console.log("trade", this.trade);
  }

  async getColors() {
    const response = await API.getColors();
    if (response.data.success) {
      this.colors = response.data.colors.map((color) => ({
        ...color,
        active: false,
      }));
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    console.log("colors", this.colors);
  }

  async getAvailableTradeColors() {
    const response = await API.getAvailableTradeColors();
    if (response.data.success) {
      this.availableColors = response.data.colors;
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    console.log("available colors", this.availableColors);
  }

  setBreadcrumbs() {
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title:
            consts.NavNames.TEMPLATES.charAt(0).toUpperCase() +
            consts.NavNames.TEMPLATES.slice(1),
          name: consts.NavNames.TEMPLATES,
        },
        {
          title:
            consts.RouterNames.TRADES.charAt(0).toUpperCase() +
            consts.RouterNames.TRADES.slice(1),
          name: consts.RouterNames.TRADES,
        },
        {
          title: this.trade["name"],
          name: "",
        },
      ],
    });
  }

  routingHandle(path: string) {
    this.$router.push({
      path: `/mainView/${path}`,
    });
  }

  filterWorkTypes() {
    if (this.filterInput !== "") {
      const filteredWorkTypes = this.workTypes.filter((wt) => {
        if (
          wt.name.toLowerCase().includes(this.filterInput.toLowerCase()) ||
          wt.code.toLowerCase().includes(this.filterInput.toLowerCase()) ||
          wt.description.toLowerCase().includes(this.filterInput.toLowerCase())
        )
          return true;
      });
      this.filteredWorkTypes = filteredWorkTypes;
    } else {
      this.filteredWorkTypes = this.workTypes;
    }
  }

  showModalDelete() {
    this.modalDelete.showModal();
  }

  async showModalTrade(trade) {
    await this.modalTrade.showModal(trade);
  }

  async modalTradeOk() {
    await this.getTrade();
    this.setBreadcrumbs();
  }

  async modalTradeCancel() {
    await this.getTrade();
  }

  showModalWorkType() {
    this.modalWorkType.showModal();
  }

  async deleteTrade() {
    const response = await API.deleteTrade(this.tradeId);
    if (response.data.success) {
      helpers.info("Trade has been deleted");
      this.$router.push({
        path: `/${consts.NavNames.MAIN_VIEW}/${consts.RouterNames.TRADES}`,
      });
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
  }

  async changeColor(colorValue) {
    if (!this.availableColors.includes(colorValue)) {
      helpers.error("This color is already used");
    } else {
      this.trade.color = colorValue;
      const response = await API.saveTrade(this.trade);
      if (response.data.success) {
        await this.getTrade();
        await this.getAvailableTradeColors();
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }
  }

  setTimeoutToShowBubble(index) {
    this.overColor = true;
    clearTimeout(this.timeoutToShow);
    this.colors = this.colors.map((color) => ({
      ...color,
      active: false,
    }));
    this.timeoutToShow = setTimeout(() => {
      if (this.overColor === true) {
        this.colors[index].active = true;
      }
    }, 2000);
    this.timeoutToHide = setTimeout(() => {
      this.colors[index].active = false;
    }, 7000);
  }

  disableShowingBubble() {
    this.overColor = false;
  }

  hideBubble(index) {
    clearTimeout(this.timeoutToShow);
    this.colors[index].active = false;
  }

  async deleteColor(index) {
    const response = await API.deleteColor(this.colors[index].id);
    if (response.data.success) {
      await this.getColors();
      await this.getAvailableTradeColors();
    } else {
      if (response.data.error_code === consts.ErrorCodes.COLOR_IS_USED) {
        console.log("Color is assigned to a trade");
        helpers.error("This color is assigned to a trade");
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }
    }
  }

  showModalAddColor() {
    this.modalAddColor.showModal(this.newColor);
  }

  async modalAddColorOk() {
    await this.getColors();
    await this.getAvailableTradeColors();
  }

  async created() {
    this.tradeId = Number(this.$route.params.trade_id);
    await this.getTrade();
    await this.getColors();
    await this.getAvailableTradeColors();
    this.setBreadcrumbs();
  }
}
