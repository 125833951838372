
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { WorkType } from "@/types";

@Component({})
export default class ModalWorkType extends Vue {
  @Prop({ type: Number, required: true }) tradeId;
  workType: WorkType = new WorkType();
  show = false;
  btnSave = 0;
  isNew = false;

  goToWorkType() {
    this.$router.push({
      path: consts.RouterNames.WORK_TYPE,
      name: consts.RouterNames.WORK_TYPE,
      params: {
        trade_id: this.tradeId,
        work_type_id: this.workType.id.toString(),
      },
    });
  }

  showModal(workType: WorkType = null) {
    if (workType === null) {
      this.isNew = true;
      this.workType = new WorkType();
    } else {
      this.workType = workType;
    }
    this.show = true;
  }

  async saveModal() {
    this.workType.trade = this.tradeId;
    const response = await API.saveWorkType(this.workType);
    if (
      response.data.success === false &&
      response.data.error_code === consts.ErrorCodes.CODE_EXISTS
    ) {
      console.log("Service code already exists");
      helpers.error("Service code already exists, please enter another");
      this.btnSave++;
      return;
    }
    if (response.data.success) {
      if (this.isNew === true) {
        this.workType.id = response.data.work_type_id;
        this.goToWorkType();
      }
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
