import { render, staticRenderFns } from "./CustomerCompanyDetail.vue?vue&type=template&id=cedb4368&scoped=true&"
import script from "./CustomerCompanyDetail.vue?vue&type=script&lang=ts&"
export * from "./CustomerCompanyDetail.vue?vue&type=script&lang=ts&"
import style0 from "./CustomerCompanyDetail.vue?vue&type=style&index=0&id=cedb4368&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cedb4368",
  null
  
)

export default component.exports