
import { Component, Prop, Vue } from "vue-property-decorator";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import moment from "moment";
import { TaskList } from "@/types";

@Component({})
export default class ModalWorkType extends Vue {
  taskList: TaskList = new TaskList();
  show = false;
  btnSave = 0;
  isNumeric = true;
  isSingleTime = false;
  singleTime = null;
  singleTimeParsedInt = null;
  sumOfTasksTime = "";

  get buttons() {
    return [
      { id: 1, name: "Single time", selected: this.isSingleTime },
      { id: 2, name: "Sum of tasks", selected: !this.isSingleTime },
    ];
  }

  showModal(taskList: TaskList) {
    this.taskList = taskList;
    this.getIsSingleTime();
    this.getSumOfTasksTime();
    this.show = true;
  }

  getIsSingleTime() {
    if (this.taskList.manual_total_time === null) {
      this.getSumOfTasksTime();
      this.isSingleTime = false;
    } else {
      this.isSingleTime = true;
      this.singleTime = moment
        .duration(this.taskList.manual_total_time)
        .asMinutes();
    }
  }

  getSumOfTasksTime() {
    const taskDurationTimes = [];
    this.taskList.tasks.forEach((t) =>
      taskDurationTimes.push(moment.duration(t.duration_time).asMinutes())
    );
    const sum = taskDurationTimes.reduce((a, b) => a + b, 0);
    this.sumOfTasksTime = `${sum} minutes from ${this.taskList.tasks.length} Tasks`;
  }

  selectTime(id) {
    this.isSingleTime = id === 1;
  }

  validateIsNumeric() {
    if (this.singleTime !== "") {
      if (typeof this.singleTime != "string") {
        this.isNumeric = false;
      } else {
        this.singleTimeParsedInt = parseInt(this.singleTime);
        this.isNumeric = !isNaN(this.singleTimeParsedInt);
      }
      if (this.isNumeric === false) {
        helpers.error("Please enter only numeric characters");
      }
    } else {
      this.isNumeric = true;
    }
  }

  async saveModal() {
    this.singleTime =
      this.isSingleTime === true ? this.singleTimeParsedInt : "";
    this.taskList.manual_total_time = this.singleTime.toString();
    const response = await API.saveTaskList(this.taskList);
    if (response.data.success === false) {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
    this.getIsSingleTime();
    this.getSumOfTasksTime();
  }
}
