
import { Component, Vue } from "vue-property-decorator";
import Avatar from "@/components/spec/Avatar.vue";
import breadcrumbModule from "@/store/modules/breadcrumb";
import CompanyLocationForm from "@/components/spec/CompanyLocationForm.vue";
import EmployeeCard from "@/components/spec/EmployeeCard.vue";
import ProfileDetailFormLine from "@/components/spec/ProfileDetailFormLine.vue";
import TechnicianCard from "@/components/spec/TechnicianCard.vue";
import TopPanel from "@/components/spec/TopPanel.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import { Company, Address, User } from "@/types";
import * as companyHelper from "@/helpers/company";

@Component({
  components: {
    Avatar,
    CompanyLocationForm,
    EmployeeCard,
    ProfileDetailFormLine,
    TechnicianCard,
    TopPanel,
  },
})
export default class CompanyDetail extends Vue {
  company: Company = new Company();
  address: Address = new Address();
  employees: User[] = [];
  firstEmployees = [];
  managers: User[] = [];
  technicians: User[] = [];
  companyId = Number(consts.TGACompany.ID); // docelowo Authorise JWT zamiast currentUser

  async uploadCompanyAvatar(imageFile) {
    await companyHelper.uploadCompanyAvatar(imageFile, this.company);
  }
  async loadCompanyAvatar() {
    return await companyHelper.loadCompanyAvatar(consts.TGACompany.ID);
  }
  async removeCompanyAvatar() {
    await companyHelper.removeCompanyAvatar(consts.TGACompany.ID);
  }

  employeesCount() {
    return this.employees.length;
  }

  async handleUpdate($emit, value) {
    if ($emit === undefined) {
      console.log("new address", value);
      await API.saveAddress(value, this.address.id);
    } else {
      const key = helpers.getKeyByValue(this.company, value);
      this.company[key] = $emit;
      console.log("new company field", $emit);
      await API.saveCompanyField(this.company[key], key, this.companyId);
    }
  }

  async created() {
    let response = await API.getCompany(this.companyId);
    if (response.data.success) {
      this.company = response.data.company;
      this.address = this.company.correspond_address;

      response = await API.getTGAManagers();
      if (response.data.success) {
        this.managers = response.data.users;
        this.managers.forEach((m) => {
          this.employees.push(m);
        });
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }

      response = await API.getTechnicians();
      if (response.data.success) {
        this.technicians = response.data.technicians;
        this.technicians.forEach((t) => {
          this.employees.push(t);
        });
      } else {
        helpers.error(consts.SERVER_COMMUNICATION_ERROR);
      }

      if (this.employees.length > 9) {
        this.firstEmployees = this.employees.slice(0, 9);
      } else {
        this.firstEmployees = this.employees;
      }
    } else {
      helpers.error(consts.SERVER_COMMUNICATION_ERROR);
    }
    breadcrumbModule.setBreadcrumb({
      routePrefix: "/" + consts.NavNames.DASHBOARD,
      items: [
        {
          title: consts.BreadcrumbTitles.TGA_COMPANY,
          name: "",
        },
      ],
    });
  }

  routingHandle(path: string) {
    if (path === consts.NavNames.DASHBOARD) {
      this.$router.push({
        path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
      });
    } else {
      this.$router.push({
        path: `/${consts.NavNames.MAIN_VIEW}/${path}`,
        name: `${path}New`,
        params: { routingFinish: consts.NavNames.TGA_COMPANY },
      });
    }
  }

  showAllManagers() {
    this.$router.push({
      path: consts.NavNames.ACCOUNTS,
      name: consts.NavNames.ACCOUNTS,
      query: {
        userType: [consts.NavNames.TGA_MANAGERS, consts.NavNames.TGA_ADMIN],
      },
    });
  }

  showAllTechnicians() {
    this.$router.push({
      path: `/${consts.NavNames.MAIN_VIEW}/${consts.NavNames.TECHNICIANS}`,
    });
  }
}
