
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Contract extends Vue {
  @Prop({ default: "red" }) dotColor!: string;
  @Prop({ default: "unknown" }) contractId!: string;
  @Prop({ default: "/unknown" }) year!: string;
  @Prop({ default: "unknown" }) grayText!: string;
}
