
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class ServiceHistoryLine extends Vue {
  @Prop({ default: "red" }) dotColor!: string;
  @Prop({ default: "gray" }) bgColor!: string;
  @Prop({ default: "unknown" }) serviceId!: string;
  @Prop({ default: "unknown" }) location!: string;
  @Prop({ default: "unknown" }) date!: string;
  @Prop({ default: "unknown" }) status!: string;
}
